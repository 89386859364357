import React, { useState } from 'react';
// import { Modal } from "antd";
import FeatherIcon from 'feather-icons-react';

import { Button } from '../../../../components/buttons/buttons';
import { Modal } from 'antd';
import { Form } from '../PessoaForm';

interface ITableButtonEditProps {
  pessoaId: string;
  refetch: () => void;
  data: IFetchProps;
}

interface IFetchProps {
  createdAt: string;
  nome: string;
  idSexo: string;
  idEstadoCivil: string;
  idNacionalidade: string;
  cpf: string;
  rg: string;
  endereco: string;
  numero: string;
  complemento: string;
  cep: string;
  bairro: string;
  cidade: string;
  uf: string;
  tel1: string;
  tel2: string;

  status: string;
}

export function TableButtonEdit({ pessoaId, refetch, data }: ITableButtonEditProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <Button className="btn-icon" type="info" onClick={() => setModalIsOpen(true)} to="#" shape="circle">
        <FeatherIcon icon="edit" size={16} />
      </Button>

      {modalIsOpen && (
        <Modal
          title="Editar"
          visible={modalIsOpen}
          onCancel={() => setModalIsOpen(false)}
          confirmLoading={true}
          footer={null}
          maskClosable={false}
          width={1200}
          style={{ marginTop: -60 }}
          bodyStyle={{ padding: 0 }}
        >
          <Form
            formType="put"
            pessoaId={pessoaId}
            initialFields={{
              nome: data.nome,
              idSexo: String(data.idSexo),
              idEstadoCivil: String(data.idEstadoCivil),
              idNacionalidade: String(data.idNacionalidade),
              cpf: data.cpf,
              rg: data.rg,
              endereco: data.endereco,
              numero: data.numero,
              complemento: data.complemento,
              cep: data.cep,
              bairro: data.bairro,
              cidade: data.cidade,

              uf: data.uf,
              tel1: data.tel1,
              tel2: data.tel2,

              status: data.status,
            }}
            setModalIsOpen={setModalIsOpen}
            refetch={refetch}
          />
        </Modal>
      )}
    </>
  );
}
