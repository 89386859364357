import React, { useState } from 'react';
// import { Modal } from "antd";
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';
import { Button } from '../../../../components/buttons/buttons';
import { Modal } from 'antd';
import { Form } from '../EstudanteForm';

interface ITableButtonEditProps {
  estudanteId: string;
  refetch: () => void;
  data: IFetchProps;
}

interface IFetchProps {
  idPessoa: string;
  pessoa: any;
  idRota: string;
  idEscola: string;
  idRotaTrajeto: string;
  deficiente: string;
  idDeficiencia: string;
  atividadeInicio: moment.Moment;
  atividadeFim: moment.Moment;
}

export function TableButtonEdit({ estudanteId, refetch, data }: ITableButtonEditProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  return (
    <>
      <Button className="btn-icon" type="info" onClick={() => setModalIsOpen(true)} to="#" shape="circle">
        <FeatherIcon icon="edit" size={16} />
      </Button>

      {modalIsOpen && (
        <Modal
          title="Editar"
          visible={modalIsOpen}
          onCancel={() => setModalIsOpen(false)}
          confirmLoading={true}
          footer={null}
          maskClosable={false}
          width={500}
          style={{ marginTop: -60 }}
          bodyStyle={{ padding: 0 }}
        >
          <Form
            formType="put"
            estudanteId={estudanteId}
            initialFields={{
              nome: data?.pessoa?.nome,
              endereco: data?.pessoa?.endereco,
              tel1: data?.pessoa?.tel1,
              idEscola: String(data.idEscola),
              idRota: String(data.idRota),
              idRotaTrajeto: String(data.idRotaTrajeto),
              idPessoa: String(data.idPessoa),
              deficiente: data.deficiente,
              idDeficiencia: data.idDeficiencia,
              atividadeInicio: data.atividadeInicio,
              atividadeFim: data.atividadeFim,
            }}
            setModalIsOpen={setModalIsOpen}
            refetch={refetch}
          />
        </Modal>
      )}
    </>
  );
}
