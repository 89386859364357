import { ColumnsType } from 'antd/lib/table';
import { IVeiculoProps } from '../../../veiculos/types';

interface ITableColumnControllerProps {
  dataList?: IVeiculoProps[];
}

export function TableColumnController({ dataList }: ITableColumnControllerProps) {
  const columns: ColumnsType<any> = [
    {
      title: 'Nome do aluno',
      dataIndex: 'siglaa',
      sorter: true,
      render: (_, data) => data.pessoa.nome,
    },
    {
      title: 'Identificação da rota',
      dataIndex: 'siglaw',
      sorter: true,
      render: (_, data) => data.idRotaTrajeto,
    },
    {
      title: 'Escola',
      dataIndex: 'siglaa',
      sorter: true,
      render: (_, data) => data.idEscola.nome,
    },
  ];

  return { columns, data: dataList };
}
