import React, { useEffect, useState } from 'react';

import { Select as AntdSelect, Form as AntdForm, Input, Button, Modal } from 'antd';
import { api } from '../../services/api';
import toast from 'react-hot-toast';
import { ISelectSegmentoComponent, IFetchProps, ISexoFetchProps, IBancoFetchProps, IMonitorFetchProps } from './types';
import { useFetch } from '../../hooks/useFetch';

export function SelectMonitor({
  title,
  postUrl = '',
  fetchUrl,
  disabled,
  name,
  onChange,
  defaultValue = '',
}: ISelectSegmentoComponent) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [dataInputModal, setDataInputModal] = useState('');

  const [selectValue, setSelectValue] = useState({ value: '', label: '' });
  const [SelectMonitor, setSelectMonitor] = useState({ value: '', label: '' });

  const { dataFetch: dataFetch2 } = useFetch<IMonitorFetchProps[]>({
    baseUrl: fetchUrl + `?filter.id=$eq:${defaultValue}`,
    isLinkProps: false,
  });

  const {
    dataFetch,
    refetch,
    setSearch,
    handleAddFiltersWithApplyFilters,
    clearFilters,
    setItensPerPage,
    isFetching,
  } = useFetch<IMonitorFetchProps[]>({ baseUrl: fetchUrl });

  async function handleNewSelect() {
    if (!dataInputModal) {
      toast.error('O campo não pode estar em branco');
      return;
    }

    const { data } = await api.post(postUrl, { nome: dataInputModal });

    setSelectValue({
      value: String(data.id),
      label: data.pessoa.nome,
    });

    setSelectMonitor({
      value: String(data.id),
      label: data.pessoa.nome,
    });

    setModalIsOpen(false);
    refetch();
  }

  function handleSelected(event: string) {
    setSelectValue({ label: event, value: event });
    setSelectMonitor({ label: event, value: event });
    if (onChange) onChange(event);
  }

  let timer: NodeJS.Timeout;

  useEffect(() => {
    setItensPerPage(1000);
  }, []);

  function debounce(event: string) {
    clearTimeout(timer);

    timer = setTimeout(() => {
      if (event) {
        setSearch(event);
        clearFilters();
      } else {
        setSearch(event);
        handleAddFiltersWithApplyFilters({
          key: '&filter.id=$eq:',
          content: defaultValue,
        });
      }
    }, 1000);
  }

  return (
    <>
      <div style={{ display: 'flex', gap: '1rem' }}>
        {isFetching ? (
          <AntdForm.Item
            label={title}
            name="banana"
            style={{ width: '100%' }}
            rules={[{ required: true, message: `Campo obrigatório` }]}
          >
            <p style={{ height: '2.35rem', padding: '5px 8px', borderRadius: '4px', border: '1px solid #CCC' }}>
              Carregando...
            </p>
          </AntdForm.Item>
        ) : (
          <AntdForm.Item
            label={title}
            name={name}
            style={{ width: '100%' }}
            rules={[{ required: true, message: `Campo obrigatório` }]}
          >
            <AntdSelect
              showSearch
              disabled={disabled}
              filterOption={false}
              placeholder={title}
              onSearch={e => debounce(e)}
              value={selectValue.value !== '' && String(selectValue.value)}
              onChange={value => handleSelected(String(value))}
            >
              {!dataFetch?.find(item => String(item.id) === defaultValue) && (
                <>
                  {dataFetch2?.map(segment => (
                    <AntdSelect.Option value={String(segment.id)}>{segment.pessoa.nome}</AntdSelect.Option>
                  ))}
                </>
              )}

              {dataFetch?.map(segment => (
                <AntdSelect.Option value={String(segment.id)}>{segment.pessoa.nome}</AntdSelect.Option>
              ))}
            </AntdSelect>
          </AntdForm.Item>
        )}

        {postUrl && (
          <Button onClick={() => setModalIsOpen(true)} style={{ marginTop: '2.20rem', height: '2.3rem' }}>
            +
          </Button>
        )}
      </div>

      <Modal
        title="Adicionar item ao select"
        visible={!postUrl ? false : modalIsOpen}
        onCancel={() => setModalIsOpen(false)}
        footer={null}
        maskClosable={false}
        width={500}
      >
        <AntdForm.Item label="Nome do item">
          <Input placeholder="nome" value={dataInputModal} onChange={e => setDataInputModal(e.target.value)} />
        </AntdForm.Item>

        <Button onClick={handleNewSelect}>Adicionar</Button>
      </Modal>
    </>
  );
}
