import React from 'react';

import { Main } from '../../container/styled';
import { ThemeLayout } from '../../layout/themeLayout';

import { PageHeader } from '../../components/page-headers/page-headers';
import { useAuth } from '../../hooks/useAuth';
import { Button, Form, Input, Spin } from 'antd';
import { api } from '../../services/api';
import { getSubDomain } from '../../hooks/getSubDomain';
import toast from 'react-hot-toast';
import { useFetch } from '../../hooks/useFetch';

export function Profile() {
  const { user } = useAuth();
  const { dataFetch } = useFetch<any>({ showV1: true, isArray: false, isLinkProps: false, baseUrl: 'auth/me' });

  async function handleUpdateUser(data: any) {
    try {
      await api.put(getSubDomain() + 'user/' + user?.id, {
        nome: data?.nome || '',
        email: user?.email,
        phone: data?.phone || '',
        idEscola: user?.idEscola,
        status: 1,
        role: user?.role,
      });

      toast.success('Usuário atualizado com sucesso');
    } catch (err) {
      const error: any = err;
      toast.error(error?.response?.data?.error || 'Erro interno');
    }
  }

  async function handleUpdatePassword(data: any) {
    try {
      await api.put(getSubDomain() + 'user/' + user?.id, {
        nome: data?.nome || '',
        email: user?.email,
        phone: data?.phone || '',
        idEscola: user?.idEscola,
        status: 1,
        role: user?.role,
      });

      toast.success('Usuário atualizado com sucesso');
    } catch (err) {
      const error: any = err;
      toast.error(error?.response?.data?.error || 'Erro interno');
    }
  }

  return (
    <ThemeLayout>
      <PageHeader ghost title="Meu perfil" />

      <Main>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 20 }}>
          {dataFetch ? (
            <Form
              onFinish={handleUpdateUser}
              layout="vertical"
              initialValues={dataFetch}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                padding: '30px',
                background: '#fff',
                borderRadius: 8,
                height: '550px',
              }}
            >
              <Form.Item label="Email:" name="email">
                <Input defaultValue={dataFetch?.email} readOnly />
              </Form.Item>
              <Form.Item label="Nome:" name="nome">
                <Input defaultValue={dataFetch?.nome} />
              </Form.Item>
              <Form.Item label="Telefone:" name="phone">
                <Input defaultValue={dataFetch?.phone} />
              </Form.Item>
              <Form.Item label="Cargo:" name="role">
                <Input defaultValue={dataFetch?.role} readOnly />
              </Form.Item>
              <Button htmlType="submit" type="primary" size="large">
                Salvar
              </Button>
            </Form>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#fff',
                borderRadius: 8,
                height: '550px',
              }}
            >
              <Spin />
            </div>
          )}

          <Form
            layout="vertical"
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              padding: '30px',
              background: '#fff',
              borderRadius: 8,
            }}
          >
            <Form.Item label="Senha antiga:">
              <Input type="password" />
            </Form.Item>

            <Form.Item label="Nova senha:">
              <Input type="password" />
            </Form.Item>

            <Form.Item label="Repetir nova senha:">
              <Input type="password" />
            </Form.Item>

            <Button type="primary" size="large">
              Salvar
            </Button>
          </Form>
        </div>
      </Main>
    </ThemeLayout>
  );
}
