import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Select } from 'antd';

import { FiltersWrapper } from '../../styles';
import moment from 'moment';
import { useCookies } from 'react-cookie';
import { api } from '../../../../services/api';
import { getSubDomain } from '../../../../hooks/getSubDomain';
import { useFetch } from '../../../../hooks/useFetch';
import { IFetchPropsEscolas } from '../../../escolas/types';
import { IFetchPropsRotas } from '../../types';
import { IFetchPropsProprietarios } from '../../../proprietarios/types';

type ITypeFilter = {
  content: string;
  key: string;
};

type FiltersProps = {
  filters: ITypeFilter[];
  clearFilters: () => void;
  handleAddFilters: (event: { key: string; content: string }) => void;
};

export function FilterDrawer({ handleAddFilters, clearFilters, filters }: FiltersProps) {
  function handleChangeFilters([date1, date2]: any) {
    const format = (date?: moment.Moment) => (date ? date.format('YYYY-MM-DD') : '');
    handleAddFilters({ key: '&filter.createdAt=$btw:', content: `${format(date1)},${format(date2.add(1, 'days'))}` });
  }

  function handleChangeCityFilter(citieid: number | string) {
    handleAddFilters({ key: '&cidadeId=', content: citieid.toString() });
  }

  function handleChangeFilter(key: string, content: string | number) {
    handleAddFilters({ key, content: content.toString() });
  }

  const { dataFetch: escolasData } = useFetch<IFetchPropsProprietarios>({ baseUrl: 'proprietarios' });

  const [dataFetch, setDataFetch] = useState<{ nome: string; sigla: string; id: number }[]>([]);
  const [cookies] = useCookies(['whats-front-token']);
  useEffect(() => {
    const token = cookies['whats-front-token'];
    api
      .get(getSubDomain() + 'cidades', { headers: { authorization: `bearer ${token}` } })
      .then(response => setDataFetch(response.data.data));
  }, []);

  return (
    <FiltersWrapper>
      <Form layout="vertical">
        <Form.Item label="Data criação">
          <DatePicker.RangePicker format="DD/MM/YYYY" onChange={dates => handleChangeFilters(dates)} />
        </Form.Item>

        <Form.Item label="Cidade">
          <Select onChange={e => handleChangeCityFilter(e)} placeholder="Selecione uma cidade">
            {dataFetch?.map(item => (
              <Select.Option value={item.id}>
                {item.nome} {`(${item.sigla})`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Motoristas">
          <Select onChange={e => handleChangeFilter('proprietario', e)} placeholder="Selecione uma escola">
            {escolasData?.map(item => (
              <Select.Option value={item.id}>{item.pessoa.nome}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>

      <footer>
        {filters.length !== 0 && (
          <Button type="primary" danger size="large" onClick={clearFilters}>
            Limpar filtros
          </Button>
        )}
      </footer>
    </FiltersWrapper>
  );
}
