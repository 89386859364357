import React from 'react';
import { Row } from 'antd';

import { ClearFilterButton } from './components/ClearFilterButton';
import { FilterButton } from './components/FilterButton';
import { SearchInput } from './components/SearchInput';
import { TableComponent } from './components/table';
import { ButtonAdd } from './components/table/TableButtonAdd';
import { StatusRadioGroup } from './components/StatusRadioGroup';

import { ThemeLayout } from '../../layout/themeLayout';
import { Main, TableWrapper } from '../../container/styled';
import { TopToolBox } from '../../container/ecommerce/Style';

import { PageHeader } from '../../components/page-headers/page-headers';
import { Cards } from '../../components/cards/frame/cards-frame';

import { useFetch } from '../../hooks/useFetch';
import { IFetchProps } from './types';

export function UsuariosApps() {
  const {
    isFetching,
    dataFetch,
    setSearch,
    setStatus,
    refetch,
    currentPage,
    itensPerPage,
    setCurrentPage,
    setColumnOrdenation,
    setItensPerPage,
    totalItens,
  } = useFetch<IFetchProps>({ baseUrl: 'usuarioapps' });

  return (
    <ThemeLayout>
      <PageHeader ghost title="Usuários APP" />

      <Main>
        <Cards headless>
          <TopToolBox>
            <Row gutter={15} className="justify-content-center">
              <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                <SearchInput handleSearch={setSearch} />
              </div>
            </Row>
          </TopToolBox>

          <TableWrapper className="table-order table-responsive">
            <TableComponent
              setCurrentPage={setCurrentPage}
              setColumnOrdenation={setColumnOrdenation}
              currentPage={currentPage}
              refetch={refetch}
              isLoading={isFetching}
              dataFetch={dataFetch}
              totalItens={totalItens}
              itensPerPage={itensPerPage}
              setItensPerPage={setItensPerPage}
            />
          </TableWrapper>
        </Cards>
      </Main>
    </ThemeLayout>
  );
}
