import React, { useState } from "react";
// import { Modal } from "antd"; 
import FeatherIcon from "feather-icons-react";
import moment from "moment";
import { Button } from "../../../../components/buttons/buttons";
import { Modal } from "antd";
import { Form } from "../EscolaForm";
import { useFetch } from "../../../../hooks/useFetch";
import { format } from "date-fns";

interface ITableButtonEditProps {
  veiculoId: string;
  refetch: () => void;
  data: IFetchProps;
}

interface IFetchProps {
  idCidade: string;
  idOperadora: string;
  nome: string;
  endereco: string;
  numero: string;
  bairro: string;
  complemento: string;
  cep: string;
  tel1: string;
  tel2: string;
  grauEscola: string;
  idEsfera: string;

  
}

export function TableButtonView({ veiculoId, refetch, data }: ITableButtonEditProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <Button 
        className="btn-icon" 
        type="info" 
        onClick={() => setModalIsOpen(true)} 
        to="#" 
        shape="circle"
        >
        <FeatherIcon icon="search" size={16} />
      </Button>

      <Modal
        title="Visualizar"
        visible={modalIsOpen}
        onCancel={() => setModalIsOpen(false)}
        confirmLoading={true}
        footer={null}
        maskClosable={false}
        width={1200}
        style={{marginTop: -60}}
        bodyStyle={{padding: 0}}
      > 
        <Form 
          formType="view"
          veiculoId={veiculoId}
          initialFields={{
            idCidade: String(data.idCidade),
            idOperadora: String(data.idOperadora),
            grauEscola: String(data.grauEscola),
            idEsfera: String(data.idEsfera),
            nome: data.nome,
            endereco: data.endereco,
            numero: data.numero,
            bairro:data.bairro,
            complemento:data.complemento,
            cep:data.cep,
            tel1:data.tel1,
            tel2:data.tel2,

          }}
          setModalIsOpen={setModalIsOpen} 
          refetch={refetch} 
        />
      </Modal>
    </>
  );
}