import React, { useRef, useState } from 'react';

import { Row, Col, Button, Input, Form as AntdForm } from 'antd';
import { CardWrapper, FormWrapper } from '../styles';
import { api } from '../../../services/api';
import { getSubDomain } from '../../../hooks/getSubDomain';
import { SelectSegmento } from '../../../components/Select';
import { UploadComponent } from '../../../components/UploadComponent';
import { IHandleSubmitProps } from '../types';
import { UploadFile } from 'antd/lib/upload/interface';
import toast from 'react-hot-toast';
import { MultiSelect } from '../../../components/MultiSelect';
import { dados } from '../../../utility/env';
import { SelectPessoa } from '../../../components/Select/SelectPessoa';
import { SelectBanco } from '../../../components/Select/SelectBanco';
import { SelectSexo } from '../../../components/Select/SelectSexo';
import { SelectEstadoCivil } from '../../../components/Select/SelectEstadoCivil';
import { SelectNacionalidade } from '../../../components/Select/SelectNacionalidade';

interface IFormInitialFields {
  nome: string;

  idSexo: string;
  idEstadoCivil: string;
  idNacionalidade: string;
  cpf: string;
  rg: string;
  endereco: string;
  numero: string;
  complemento: string;
  cep: string;
  bairro: string;
  cidade: string;

  uf: string;
  tel1: string;
  tel2: string;

  status: string;
}

interface IFormComponent {
  formType: 'put' | 'post' | 'view';
  initialFields?: IFormInitialFields;
  refetch: () => void;
  pessoaId?: string;
  setModalIsOpen: (event: boolean) => void;
}

export function Form({
  formType,
  setModalIsOpen,
  refetch,
  pessoaId,
  initialFields = {
    nome: '',
    idSexo: '',
    idEstadoCivil: '',
    idNacionalidade: '',
    cpf: '',
    rg: '',
    endereco: '',
    numero: '',
    complemento: '',
    cep: '',
    bairro: '',
    cidade: '',
    uf: '',
    tel1: '',
    tel2: '',
    status: '',
  },
}: IFormComponent) {
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef<any>(null);

  function clearFieldsAndCloseModal() {
    setModalIsOpen(false);
  }

  async function handleSubmit(data: IHandleSubmitProps) {
    setIsLoading(true);

    const formData = {
      ...data,
    };

    try {
      if (formType === 'post') {
        await api.post(getSubDomain() + 'pessoas', formData);
        formRef.current.resetFields();
      } else {
        if (pessoaId) await api.put(getSubDomain() + `pessoas/${pessoaId}`, formData);
        formRef.current.resetFields();
      }

      setModalIsOpen(false);

      window.location.reload();
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
    refetch();
  }

  return (
    <FormWrapper>
      <AntdForm
        name="basic"
        onFinish={handleSubmit}
        style={{ padding: '30px' }}
        layout="vertical"
        initialValues={initialFields}
        ref={formRef}
      >
        <Row gutter={40}>
          <Col span={12}>
            <CardWrapper type="inner" title="Informações Gerais">
              <AntdForm.Item label="Nome" name="nome" rules={[{ required: true, message: 'Nome obrigatório' }]}>
                <Input placeholder="Nome Completo" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>

              <SelectSexo name="idSexo" title="Sexo" disabled={formType === 'view' ? true : false} fetchUrl="sexos" />

              <SelectEstadoCivil
                name="idEstadoCivil"
                title="Estado Civil"
                fetchUrl="estadocivils"
                disabled={formType === 'view' ? true : false}
              />

              <SelectNacionalidade
                name="idNacionalidade"
                title="Nacionalidade"
                fetchUrl="nacionalidades"
                disabled={formType === 'view' ? true : false}
              />

              <AntdForm.Item
                label="CPF"
                name="cpf"
                rules={[
                  { required: true, message: 'Campo Obrigatório!' },
                  { max: 11, message: 'O campo deve conter no máximo 11 caracteres.' },
                ]}
              >
                <Input placeholder="CPF" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>

              <AntdForm.Item
                label="RG"
                name="rg"
                rules={[
                  { required: true, message: 'Campo Obrigatório!' },
                  { max: 20, message: 'O campo deve conter no máximo 20 caracteres.' },
                ]}
              >
                <Input placeholder="RG" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>
            </CardWrapper>
          </Col>

          <Col span={12}>
            <CardWrapper type="inner" title="Dados de Endereço">
              <AntdForm.Item
                label="Rua"
                name="endereco"
                rules={[
                  { required: true, message: 'Campo Obrigatório!' },
                  { max: 120, message: 'O campo deve conter no máximo 120 caracteres.' },
                ]}
              >
                <Input placeholder="Endereço" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>
              <Row gutter={15}>
                <Col span={8}>
                  <AntdForm.Item
                    label="Número"
                    name="numero"
                    rules={[
                      { required: true, message: 'Campo Obrigatório!' },
                      { max: 120, message: 'O campo deve conter no máximo 120 caracteres.' },
                    ]}
                  >
                    <Input placeholder="Número" disabled={formType === 'view' ? true : false} />
                  </AntdForm.Item>
                </Col>
                <Col span={16}>
                  <AntdForm.Item
                    label="Complemento"
                    name="complemento"
                    rules={[{ max: 120, message: 'O campo deve conter no máximo 120 caracteres.' }]}
                  >
                    <Input placeholder="Complemento" disabled={formType === 'view' ? true : false} />
                  </AntdForm.Item>
                </Col>
              </Row>

              <Row gutter={15}>
                <Col span={12}>
                  <AntdForm.Item
                    label="CEP"
                    name="cep"
                    rules={[
                      { required: true, message: 'Campo Obrigatório!' },
                      { max: 8, message: 'O campo deve conter no máximo 8 caracteres.' },
                    ]}
                  >
                    <Input placeholder="CEP" disabled={formType === 'view' ? true : false} />
                  </AntdForm.Item>
                </Col>
                <Col span={12}>
                  <AntdForm.Item
                    label="Bairro"
                    name="bairro"
                    rules={[
                      { required: true, message: 'Campo Obrigatório!' },
                      { max: 45, message: 'O campo deve conter no máximo 45 caracteres.' },
                    ]}
                  >
                    <Input placeholder="Bairro" disabled={formType === 'view' ? true : false} />
                  </AntdForm.Item>
                </Col>
              </Row>
              <Row gutter={15}>
                <Col span={16}>
                  <AntdForm.Item
                    label="Cidade"
                    name="cidade"
                    rules={[
                      { required: true, message: 'Campo Obrigatório!' },
                      { max: 45, message: 'O campo deve conter no máximo 45 caracteres.' },
                    ]}
                  >
                    <Input placeholder="Cidade" disabled={formType === 'view' ? true : false} />
                  </AntdForm.Item>
                </Col>
                <Col span={8}>
                  <AntdForm.Item
                    label="UF"
                    name="uf"
                    rules={[
                      { required: true, message: 'Campo Obrigatório!' },
                      { max: 2, message: 'O campo deve conter no máximo 2 caracteres.' },
                    ]}
                  >
                    <Input placeholder="Estado" disabled={formType === 'view' ? true : false} />
                  </AntdForm.Item>
                </Col>
              </Row>
              <AntdForm.Item
                label="Telefone 1"
                name="tel1"
                rules={[
                  { required: true, message: 'Campo Obrigatório!' },
                  { max: 14, message: 'O campo deve conter no máximo 14 caracteres.' },
                ]}
              >
                <Input placeholder="Telefone" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>

              <AntdForm.Item
                label="Telefone 2"
                name="tel2"
                rules={[{ max: 14, message: 'O campo deve conter no máximo 14 caracteres.' }]}
              >
                <Input placeholder="Telefone" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>
            </CardWrapper>
          </Col>
        </Row>
        {formType == 'view' ? (
          <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
            <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
              Fechar
            </Button>
          </footer>
        ) : (
          <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
            <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
              Voltar
            </Button>

            <Button type="primary" htmlType="submit" loading={isLoading}>
              Salvar
            </Button>
          </footer>
        )}
      </AntdForm>
    </FormWrapper>
  );
}
