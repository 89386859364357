import React, { useRef, useState } from "react";

import { Button, Input, Select, Form as AntdForm } from "antd";
import { CardWrapper, FormWrapper } from "../styles";
import { api } from "../../../services/api";
import { getSubDomain } from "../../../hooks/getSubDomain";
import { SelectSegmento } from "../../../components/Select";
import { UploadComponent } from "../../../components/UploadComponent";
import { IHandleSubmitProps } from "../types";
import { UploadFile } from "antd/lib/upload/interface";
import toast from "react-hot-toast";
import FeatherIcon from 'feather-icons-react';

interface IFormInitialFields {
  estadoCivil: string;
  
   status: number;

  //select: { label: string; value: string; };
}

interface IFormComponent {
  formType: "put" | "post" | "view",
  initialFields?: IFormInitialFields;
  refetch: () => void;
 
  contactId?: string;
  setModalIsOpen: (event: boolean) => void;
}

export function Form({ 
  formType,
  setModalIsOpen, 
  refetch, 
  contactId,
  initialFields = { 
    estadoCivil: "", 
     
    status: 1 
  } //,  select: { label: "", value: "" }
}: IFormComponent) {
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([] as UploadFile[])

  const formRef = useRef<any>(null);
  const [status, setStatus] = useState(initialFields.status);
  /*  const [nome, setNome] = useState(initialFields.nome);
  const [uf, setUf] = useState(initialFields.uf);
 
  const [sigla, setSigla] = useState(initialFields.sigla);  */
  function clearFieldsAndCloseModal() {
    setModalIsOpen(false);
   
  }
  //if(formType === "post") !!initialFields;

 

  async function handleSubmit(data: IHandleSubmitProps) {
    setIsLoading(true);
    /* if(fileList.length <= 0) {
      toast.error("É necessário indexar um arquivo");
      setIsLoading(false);
      return;
    } */

    const formData = {
      ...data,
     // url: fileList[0].response.filename,
    }

    try {
      if(formType === "post") {
        await api.post(getSubDomain() + "estadocivils", formData);
       // this.preventDefault();
       
       
        toast.success("Salvo com sucesso");
       
      } else {
        if(contactId) await api.put(getSubDomain() + `estadocivils/${contactId}`, formData);
        toast.success("Salvo com sucesso");
      
      }
      setModalIsOpen(false);
       
      formRef.current.resetFields()
      setFileList([]);
    } catch(err) {
      console.log(err)
      toast.error("Erro ao Salvar");
    }
    
    setIsLoading(false);
    refetch();
  }


  
  return (
   
<FormWrapper>
      <AntdForm 
        name="basic" 
      
        onFinish={handleSubmit} 
        style={{ padding: "30px" }}
        layout="vertical"
        initialValues={initialFields}
        ref={formRef}
      >
        <CardWrapper type="inner" title="Informações Gerais">
      {/*   <AntdForm.Item 
          label="Status" 
          name="status"
          rules={[
            { required: true, message: 'Campo Obrigatório!' },
           
          ]}>
                <Select
                  placeholder="Status"
                  value={status}
                  
                  onChange={e => setStatus(e)}
                >
                    <Select.Option value={1}>Ativo</Select.Option>
                    <Select.Option value={0}>Inativo</Select.Option>
                </Select>
              </AntdForm.Item> */}

              
          <AntdForm.Item 
          label="Estado Civil" 
          name="estadoCivil"
          rules={[
            { required: true, message: 'Campo Obrigatório!' },
          ]} 
          wrapperCol={{ span: 16 }}>
            <Input placeholder="Estado Civil" />
          </AntdForm.Item>

         

          {/* <SelectSegmento
            name="segmentId"
            title="segmento"
            postUrl="segments"
            fetchUrl="segments"
          />

          <SelectSegmento
            name="tag"
            title="Selecione uma tag"
            postUrl="segments"
            fetchUrl="segments"
          /> */}
          {/* <UploadComponent
            maxCount={1}
                disabled={formType ==='view'? true:false}
            fileList={fileList}
            setFileList={setFileList}
          /> */}
        </CardWrapper>

      

        <footer style={{ padding: "20px 30px", borderTop: "1px solid #f0f0f0" }}>
          <Button type="default" size="large" htmlType="button" onClick={clearFieldsAndCloseModal}>
          
             Cancelar
          </Button>

          <Button type="primary" size="large" htmlType="submit"  loading={isLoading}
        
         >
          <FeatherIcon icon="check" size={14} style={{ marginRight:10  }} />
             Salvar
             
          </Button>
        </footer>
      </AntdForm>
    </FormWrapper>
  
    
  );
}
