import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Select } from 'antd';

import { FiltersWrapper } from '../../styles';
import moment from 'moment';
import { useCookies } from 'react-cookie';
import { api } from '../../../../services/api';
import { getSubDomain } from '../../../../hooks/getSubDomain';

type ITypeFilter = {
  content: string;
  key: string;
};

type FiltersProps = {
  filters: ITypeFilter[];

  clearFilters: () => void;
  handleAddFilters: (event: { key: string; content: string }) => void;
  handleChangeCityFilter: (citieid: string | number, cityName: string) => void;
};

export function FilterDrawer({ handleChangeCityFilter, handleAddFilters, clearFilters, filters }: FiltersProps) {
  function handleChangeFilters([date1, date2]: any) {
    const format = (date?: moment.Moment) => (date ? date.format('YYYY-MM-DD') : '');
    handleAddFilters({ key: '&filter.createdAt=$btw:', content: `${format(date1)},${format(date2.add(1, 'days'))}` });
  }

  const [dataFetch, setDataFetch] = useState<{ nome: string; sigla: string; id: number }[]>([]);
  const [cookies] = useCookies(['whats-front-token']);
  useEffect(() => {
    const token = cookies['whats-front-token'];
    api
      .get(getSubDomain() + 'cidades', { headers: { authorization: `bearer ${token}` } })
      .then(response => setDataFetch(response.data.data));
  }, []);

  return (
    <FiltersWrapper>
      <Form layout="vertical">
        <Form.Item label="Data criação">
          <DatePicker.RangePicker format="DD/MM/YYYY" onChange={dates => handleChangeFilters(dates)} />
        </Form.Item>

        <Form.Item label="Cidade">
          <Select
            onChange={e => handleChangeCityFilter(e.split('/:/')[0], e.split('/:/')[1])}
            placeholder="Selecione uma cidade"
          >
            {dataFetch?.map(item => (
              <Select.Option value={`${item.id}/:/${item.nome}`}>
                {item.nome} {`(${item.sigla})`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      <footer>
        {filters.length !== 0 && (
          <Button type="primary" danger size="large" onClick={clearFilters}>
            Limpar filtros
          </Button>
        )}
      </footer>
    </FiltersWrapper>
  );
}
