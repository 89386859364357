import React from 'react';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';

import { IMonitorProps } from '../../types';
import { TableButtonDelete } from './TableButtonDelete';
import { TableButtonEdit } from './TableButtonEdit';
import { TableButtonView } from './TableButtonView';
import { TableButtonRestore } from './TableButtonRestore';

interface ITableColumnControllerProps {
  handleDeleteFunction: (monitorId: string) => Promise<void>;
  handleRestoreFunction: (monitorId: string) => Promise<void>;
  refetch: () => void;
  dataList?: IMonitorProps[];
}

export function TableColumnController({
  handleDeleteFunction,
  handleRestoreFunction,
  refetch,
  dataList,
}: ITableColumnControllerProps) {
  const columns: ColumnsType<IMonitorProps> = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      sorter: true,
    },

    {
      title: 'Ações',
      dataIndex: 'actions',
      render: (_, item) => {
        return (
          <div className="table-actions">
            {item.status === 0 ? (
              <TableButtonRestore handleRestoreFunction={handleRestoreFunction} contactId={String(item.id)} />
            ) : (
              <>
                <TableButtonView monitorId={String(item.id)} refetch={refetch} data={item} />
                <TableButtonEdit monitorId={String(item.id)} refetch={refetch} data={item} />

                <TableButtonDelete handleDeleteFunction={handleDeleteFunction} monitorId={String(item.id)} />
              </>
            )}
          </div>
        );
      },
    },
  ];

  const data: any[] = [];

  dataList?.forEach(item => {
    data.push({
      key: item.id,
      id: item.id,
      curriculo: item.curriculo,
      nome: item.pessoa.nome,
      comprovanteResidencia: item.comprovanteResidencia,
      createdAt: moment(item.createdAt).format('DD/MM/YYYY'),
      idPessoa: item.idPessoa,
      antecedentes: item.antecedentes,
      certidaoCriminal: item.certidaoCriminal,
      certidaoCriminalVencimento: moment(item.certidaoCriminalVencimento),
      atividadeInicio: moment(item.atividadeInicio),
      atividadeFim: moment(item.atividadeFim),
      status: item.status,
    });
  });

  return { columns, data };
}
