import React, { useState } from 'react';
// import { Modal } from "antd";
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';
import { Button } from '../../../../components/buttons/buttons';
import { Modal } from 'antd';
import { Form } from '../EstudanteForm';

interface ITableButtonEditProps {
  estudanteId: string;
  refetch: () => void;
  data: IFetchProps;
}

interface IFetchProps {
  idPessoa: string;
  pessoa: any;
  idEscola: string;
  idRotaTrajeto: string;
  deficiente: string;
  idRota: string;
  idDeficiencia: string;
  atividadeInicio: moment.Moment;
  atividadeFim: moment.Moment;
}

export function TableButtonView({ estudanteId, refetch, data }: ITableButtonEditProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  console.log(data);
  return (
    <>
      <Button className="btn-icon" type="info" onClick={() => setModalIsOpen(true)} to="#" shape="circle">
        <FeatherIcon icon="search" size={16} />
      </Button>

      <Modal
        title="Visualizar"
        visible={modalIsOpen}
        onCancel={() => setModalIsOpen(false)}
        confirmLoading={true}
        footer={null}
        maskClosable={false}
        width={1200}
        style={{ marginTop: -60 }}
        bodyStyle={{ padding: 0 }}
      >
        <Form
          formType="view"
          estudanteId={estudanteId}
          initialFields={{
            idRota: String(data.idRota),
            idEscola: String(data.idEscola),
            idRotaTrajeto: String(data.idRotaTrajeto),
            idPessoa: String(data.idPessoa),
            deficiente: data.deficiente,
            idDeficiencia: data.idDeficiencia,
            atividadeInicio: data.atividadeInicio,
            atividadeFim: data.atividadeFim,
          }}
          setModalIsOpen={setModalIsOpen}
          refetch={refetch}
        />
      </Modal>
    </>
  );
}
