import React, { useState } from 'react';
// import { Modal } from "antd";
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';
import { Button } from '../../../../components/buttons/buttons';
import { Modal } from 'antd';
import { Form } from '../VeiculoForm';
import { useFetch } from '../../../../hooks/useFetch';
import { format } from 'date-fns';

interface ITableButtonEditProps {
  veiculoId: string;
  refetch: () => void;
  data: IFetchProps;
}

interface IFetchProps {
  idProprietario: string;
  marca: string;
  modelo: string;
  anoFabricacao: string;
  anoModelo: string;
  renavam: string;
  chassi: string;
  potencia: string;
  placa: string;
  cilindrada: string;
  corPredominante: string;
  capacidade: string;
  idGetrak: string;
  usoInicio: moment.Moment;
  usoFim: moment.Moment;
  crlv: string;
  crlvVencimento: moment.Moment;
  vistoria: string;
  vistoriaVencimento: moment.Moment;
  seguroEstudante: string;
  seguroEstudanteVencimento: moment.Moment;
  termoUso: string;
}

export function TableButtonEdit({ veiculoId, refetch, data }: ITableButtonEditProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <Button className="btn-icon" type="info" onClick={() => setModalIsOpen(true)} to="#" shape="circle">
        <FeatherIcon icon="edit" size={16} />
      </Button>

      <Modal
        title="Editar"
        visible={modalIsOpen}
        onCancel={() => setModalIsOpen(false)}
        confirmLoading={true}
        footer={null}
        maskClosable={false}
        width={1200}
        style={{ marginTop: -60 }}
        bodyStyle={{ padding: 0 }}
      >
        <Form
          formType="put"
          veiculoId={veiculoId}
          initialFields={{
            idProprietario: String(data.idProprietario),
            marca: data.marca,
            modelo: data.modelo,
            placa: data.placa,
            anoFabricacao: data.anoFabricacao,
            anoModelo: data.anoModelo,
            renavam: data.renavam,
            chassi: data.chassi,
            idGetrak: data.idGetrak,
            potencia: data.potencia,
            cilindrada: data.cilindrada,
            corPredominante: data.corPredominante,
            capacidade: data.capacidade,
            usoInicio: moment(data.usoInicio),
            usoFim: moment(data.usoFim),
            crlv: data.crlv,
            crlvVencimento: moment(data.crlvVencimento),
            vistoria: data.vistoria,
            vistoriaVencimento: moment(data.vistoriaVencimento),
            seguroEstudante: data.seguroEstudante,
            seguroEstudanteVencimento: moment(data.seguroEstudanteVencimento),
            termoUso: data.termoUso,
          }}
          setModalIsOpen={setModalIsOpen}
          refetch={refetch}
        />
      </Modal>
    </>
  );
}
