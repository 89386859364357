import React from 'react';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';

import { IRotastrajetoProps } from '../../types';
import { TableButtonDelete } from './TableButtonDelete';
import { TableButtonEdit } from './TableButtonEdit';
import { TableButtonView } from './TableButtonView';
import { TableButtonRestore } from './TableButtonRestore';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../../../components/buttons/buttons';
import FeatherIcon from 'feather-icons-react';

interface ITableColumnControllerProps {
  handleDeleteFunction: (rotastrajetoId: string) => Promise<void>;
  handleRestoreFunction: (rotastrajetoId: string) => Promise<void>;
  refetch: () => void;
  dataList?: IRotastrajetoProps[];
}

export function TableColumnController({
  handleDeleteFunction,
  handleRestoreFunction,
  refetch,
  dataList,
}: ITableColumnControllerProps) {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const columns: ColumnsType<IRotastrajetoProps> = [
    {
      title: 'Descricao',
      dataIndex: 'descricao',
      sorter: true,
    },

    {
      title: 'Ações',
      dataIndex: 'actions',
      render: (_, item) => {
        return (
          <div className="table-actions">
            {item.status === 0 ? (
              <TableButtonRestore handleRestoreFunction={handleRestoreFunction} contactId={String(item.id)} />
            ) : (
              <>
                <TableButtonView rotastrajetoId={String(item.id)} refetch={refetch} data={item} />
                <Button
                  className="btn-icon"
                  type="info"
                  onClick={() => history.push('/estudantes/?idRotatrajeto=' + item.id + `&back=${pathname + search}`)}
                  to="#"
                  shape="circle"
                >
                  <FeatherIcon icon="list" size={16} />
                </Button>
                <TableButtonEdit rotastrajetoId={String(item.id)} refetch={refetch} data={item} />

                <TableButtonDelete handleDeleteFunction={handleDeleteFunction} rotastrajetoId={String(item.id)} />
              </>
            )}
          </div>
        );
      },
    },
  ];

  const data: any[] = [];

  console.log(dataList);

  dataList?.forEach(item => {
    data.push({
      key: item.id,
      id: item.id,
      idRota: String(item.rota.id),
      etapa: item.etapa,
      descricao: item.descricao,
      latitude: item.latitude,
      longitude: item.longitude,
      status: item.status,
      createdAt: moment(item.createdAt).format('DD/MM/YYYY'),
    });
  });

  return { columns, data };
}
