import React from 'react';
import { Button, Row } from 'antd';

import { ClearFilterButton } from './components/ClearFilterButton';
import { FilterButton } from './components/FilterButton';
import { SearchInput } from './components/SearchInput';
import { TableComponent } from './components/table';
import { ButtonAdd } from './components/table/TableButtonAdd';
import { StatusRadioGroup } from './components/StatusRadioGroup';

import { ThemeLayout } from '../../layout/themeLayout';
import { Main, TableWrapper } from '../../container/styled';
import { TopToolBox } from '../../container/ecommerce/Style';

import { PageHeader } from '../../components/page-headers/page-headers';
import { Cards } from '../../components/cards/frame/cards-frame';

import { useFetch } from '../../hooks/useFetch';
import { IFetchPropsRotastrajetos } from './types';
import { useHistory } from 'react-router-dom';

export function Rotastrajetos() {
  const {
    isFetching,
    dataFetch,
    setSearch,
    filters,
    setStatus,
    handleAddFilters,
    clearFilters,
    refetch,
    currentPage,
    itensPerPage,
    setCurrentPage,
    setColumnOrdenation,
    setItensPerPage,
    totalItens,
  } = useFetch<IFetchPropsRotastrajetos>({ baseUrl: 'rotatrajetos' });

  const history = useHistory();

  const search = window.location.search;
  const params = new URLSearchParams(search);

  const rotaName = params.get('rotaName');
  const title = 'Trajetos da Rota: ' + rotaName;

  return (
    <ThemeLayout>
      <PageHeader
        ghost
        title={title}
        buttons={[
          <div key="1" className="page-header-actions">
            <Button onClick={() => history.push('/rotas')}>Voltar</Button>

            {filters.length !== 0 && <ClearFilterButton clearFilter={clearFilters} />}
            <FilterButton filters={filters} handleAddFilters={handleAddFilters} clearFilters={clearFilters} />
          </div>,
        ]}
      />

      <Main>
        <Cards headless>
          <TopToolBox>
            <Row gutter={15} className="justify-content-center">
              <SearchInput handleSearch={setSearch} />
              <StatusRadioGroup setStatus={setStatus} />
              <ButtonAdd refetch={refetch} />
            </Row>
          </TopToolBox>

          <TableWrapper className="table-order table-responsive">
            <TableComponent
              setCurrentPage={setCurrentPage}
              setColumnOrdenation={setColumnOrdenation}
              currentPage={currentPage}
              refetch={refetch}
              isLoading={isFetching}
              dataFetch={dataFetch}
              totalItens={totalItens}
              itensPerPage={itensPerPage}
              setItensPerPage={setItensPerPage}
            />
          </TableWrapper>
        </Cards>
      </Main>
    </ThemeLayout>
  );
}
