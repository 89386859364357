import React from 'react';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';

import { IEmpresaProps } from '../../types';
import { TableButtonDelete } from './TableButtonDelete';
import { TableButtonEdit } from './TableButtonEdit';
import { TableButtonView } from './TableButtonView';
import { TableButtonRestore } from './TableButtonRestore';

interface ITableColumnControllerProps {
  handleDeleteFunction: (empresaId: string) => Promise<void>;
  handleRestoreFunction: (empresaId: string) => Promise<void>;
  refetch: () => void;
  dataList?: IEmpresaProps[];
}

export function TableColumnController({
  handleDeleteFunction,
  handleRestoreFunction,
  refetch,
  dataList,
}: ITableColumnControllerProps) {
  const columns: ColumnsType<IEmpresaProps> = [
    {
      title: 'Razão Social',
      dataIndex: 'razaoSocial',
      sorter: true,
    },

    {
      title: 'CNPJ',
      dataIndex: 'cnpj',
      sorter: true,
    },

    {
      title: 'Ações',
      dataIndex: 'actions',
      render: (_, item) => {
        return (
          <div className="table-actions">
            {item.status === 0 ? (
              <TableButtonRestore handleRestoreFunction={handleRestoreFunction} contactId={String(item.id)} />
            ) : (
              <>
                <TableButtonView empresaId={String(item.id)} refetch={refetch} data={item} />
                <TableButtonEdit empresaId={String(item.id)} refetch={refetch} data={item} />

                <TableButtonDelete handleDeleteFunction={handleDeleteFunction} empresaId={String(item.id)} />
              </>
            )}
          </div>
        );
      },
    },
  ];

  const data: any[] = [];

  dataList?.forEach(item => {
    data.push({
      key: item.id,
      id: item.id,

      razaoSocial: item.razaoSocial,
      bancoAgencia: item.bancoAgencia,
      contratoSocial: item.contratoSocial,
      bancoConta: item.bancoConta,
      idBanco: String(item.idBanco),
      createdAt: moment(item.createdAt).format('DD/MM/YYYY'),
      idPessoa: String(item.idPessoa),
      cnpj: item.cnpj,

      status: item.status,
    });
  });

  return { columns, data };
}
