import React from 'react';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';

import { IItemProps } from '../../types';
import { TableButtonDelete } from './TableButtonDelete';
import { TableButtonEdit } from './TableButtonEdit';
import { TableButtonRestore } from './TableButtonRestore';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

interface ITableColumnControllerProps {
  handleDeleteFunction: (contactId: string) => Promise<void>;
  handleRestoreFunction: (contactId: string) => Promise<void>;
  refetch: () => void;
  dataList?: IItemProps[];
}

export function TableColumnController({
  handleDeleteFunction,
  handleRestoreFunction,
  refetch,
  dataList,
}: ITableColumnControllerProps) {
  const history = useHistory();

  const columns: ColumnsType<IItemProps> = [
    {
      title: 'Data',
      dataIndex: 'nome',
      sorter: true,
      render: (_, data) => <p style={{ margin: 'auto 0' }}>{new Date(data.data).toLocaleDateString()}</p>,
    },

    {
      title: 'Condutor',
      dataIndex: 'data',
      sorter: true,
      render: (_, data) => <p style={{ margin: 'auto 0' }}>{data?.rota?.sigla ? data.rota.sigla : '---'}</p>,
    },
    {
      title: 'Rota',
      dataIndex: 'data',
      sorter: true,
      render: (_, data) => (
        <p style={{ margin: 'auto 0' }}>{data?.condutor?.pessoa?.nome ? data.condutor.pessoa.nome : '---'}</p>
      ),
    },
    {
      title: 'Enviado Em: ',
      dataIndex: 'data',
      sorter: true,
      render: (_, data) => (
        <p style={{ margin: 'auto 0' }}>
          {new Date(data.enviado).toLocaleDateString()} {new Date(data.enviado).toLocaleTimeString()}
        </p>
      ),
    },
    {
      title: '',
      dataIndex: '',
      render: (_, data) => (
        <Button
          onClick={() =>
            history.push(
              `/diarioestudantes/${data.id}/${data.rota.sigla}/${new Date(data.enviado)
                .toLocaleDateString()
                .replaceAll('/', 'Z')}`,
            )
          }
        >
          Ver Presenças
        </Button>
      ),
    },
  ];

  return { columns, data: dataList };
}
