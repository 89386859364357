import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';

import { Button } from '../../../../components/buttons/buttons';
import { Modal } from 'antd';
import { IItemProps } from '../../types';
import { Form } from '../ItemForm';

interface ITableButtonEditProps {
  contactId: string;
  refetch: () => void;
  data: IItemProps;
}

export function TableButtonEdit({ contactId, refetch, data }: ITableButtonEditProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  return (
    <>
      <Button className="btn-icon" type="info" onClick={() => setModalIsOpen(true)} to="#" shape="circle">
        <FeatherIcon icon="edit" size={16} />
      </Button>

      <Modal
        title="Visualizar Ocorrência"
        visible={modalIsOpen}
        onCancel={() => setModalIsOpen(false)}
        confirmLoading={true}
        footer={null}
        maskClosable={false}
        width={1000}
        style={{ marginTop: -60 }}
        bodyStyle={{ padding: 0 }}
      >
        <Form formType="put" initialFields={data} setModalIsOpen={setModalIsOpen} refetch={refetch} />
      </Modal>
    </>
  );
}
