import { ColumnsType } from 'antd/lib/table';

import { IRotaProps } from '../../types';
import { useHistory } from 'react-router-dom';

interface ITableColumnControllerProps {
  handleDeleteFunction: (rotaId: string) => Promise<void>;
  handleRestoreFunction: (rotaId: string) => Promise<void>;
  refetch: () => void;
  dataList?: IRotaProps[];
}

export function TableColumnController({ dataList }: ITableColumnControllerProps) {
  const history = useHistory();

  const columns: ColumnsType<any> = [
    {
      title: 'Nome',
      dataIndex: 'siglaa',
      sorter: true,
      render: (_, data) => data.pessoa.nome,
    },
    {
      title: 'Endereço',
      dataIndex: 'siglaw',
      sorter: true,
      render: (_, data) => data.pessoa.endereco,
    },
    {
      title: 'Telefone',
      dataIndex: 'sigl4a',
      sorter: true,
      render: (_, data) => data.pessoa.tel1,
    },
    {
      title: 'Municipio',
      dataIndex: 'sigla7',
      sorter: true,
      render: (_, data) => data.pessoa.cidade,
    },
  ];

  return { columns, data: dataList };
}
