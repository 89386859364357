import React from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import Cookie from 'js-cookie';
import styled from 'styled-components';
import { useAuth } from '../hooks/useAuth';
// import jwt_decode from "jwt-decode";
const { SubMenu } = Menu;

const NavLinkStyle = styled(NavLink)`
  transform: translateX(-30px);
`;

const MenuStyle = styled(Menu)`
  .ant-menu-title-content {
    padding-left: 0 !important;
    margin-left: 0 !important;
  }

  .ant-menu-submenu {
    .ant-menu-title-content {
      padding-left: 10px !important;
    }

    li {
      .ant-menu-title-content {
        padding-left: 0px !important;
        margin-left: 0 !important;
      }
    }
  }
`;

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath?.split('/') || [];
  const token = JSON.parse(Cookie.get('whats-front-user'));
  const [openKeys, setOpenKeys] = React.useState(JSON.parse(localStorage.getItem('menukeyitem')) || []);

  const { user } = useAuth();
  const emails = ['suportenorteconsultoria@gmail.com', 'fw.solucoesdigitais@gmail.com', 'muriloh@gmail.com'];

  const exibleRelatories = !!emails.includes(user.email);

  const onOpenChange = keys => {
    localStorage.setItem(
      'menukeyitem',
      JSON.stringify(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys),
    );
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  switch (token.role) {
    case 'SUPERADMIN':
      return (
        <MenuStyle
          mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
          theme="light"
          defaultSelectedKeys={[location.pathname]}
          onOpenChange={onOpenChange}
          onClick={onClick}
          openKeys={openKeys}
        >
          {!topMenu && <p className="sidebar-nav-title">Principal</p>}
          {exibleRelatories && (
            <SubMenu key="relatorios" icon={!topMenu && <FeatherIcon icon="check-square" />} title="Relatorios">
              <Menu.Item
                icon={
                  !topMenu && (
                    <NavLink className="menuItem-iocn" to={`/relatorio/alunos`}>
                      <FeatherIcon icon="check-square" />
                    </NavLink>
                  )
                }
                key="/relatorio/alunos"
              >
                <NavLinkStyle onClick={toggleCollapsed} to={`/relatorio/alunos`}>
                  Alunos
                </NavLinkStyle>
              </Menu.Item>
              <Menu.Item
                icon={
                  !topMenu && (
                    <NavLink className="menuItem-iocn" to={`/relatorio/alunosescolas`}>
                      <FeatherIcon icon="check-square" />
                    </NavLink>
                  )
                }
                key="admin/relatorio/alunosescolas"
              >
                <NavLinkStyle onClick={toggleCollapsed} to={`/relatorio/alunosescolas`}>
                  Alunos x Escola
                </NavLinkStyle>
              </Menu.Item>
              <Menu.Item
                icon={
                  !topMenu && (
                    <NavLink className="menuItem-iocn" to={`/relatorio/veiculodiasrodados`}>
                      <FeatherIcon icon="check-square" />
                    </NavLink>
                  )
                }
                key="/relatorio/veiculodiasrodados"
              >
                <NavLinkStyle onClick={toggleCollapsed} to={`/relatorio/veiculodiasrodados`}>
                  Veiculos x Dias rodados
                </NavLinkStyle>
              </Menu.Item>
              <Menu.Item
                icon={
                  !topMenu && (
                    <NavLink className="menuItem-iocn" to={`/relatorio/onibus`}>
                      <FeatherIcon icon="check-square" />
                    </NavLink>
                  )
                }
                key="/relatorio/onibus"
              >
                <NavLinkStyle onClick={toggleCollapsed} to={`/relatorio/onibus`}>
                  Onibus
                </NavLinkStyle>
              </Menu.Item>
              <Menu.Item
                icon={
                  !topMenu && (
                    <NavLink className="menuItem-iocn" to={`/relatorio/onibus-condutor`}>
                      <FeatherIcon icon="check-square" />
                    </NavLink>
                  )
                }
                key="/relatorio/onibus-condutor"
              >
                <NavLinkStyle onClick={toggleCollapsed} to={`/relatorio/onibus-condutor`}>
                  Onibus x Motorista
                </NavLinkStyle>
              </Menu.Item>

              <Menu.Item
                icon={
                  !topMenu && (
                    <NavLink className="menuItem-iocn" to={`/relatorio/alunosescolasrotas`}>
                      <FeatherIcon icon="check-square" />
                    </NavLink>
                  )
                }
                key="/relatorio/alunosescolasrotas"
              >
                <NavLinkStyle onClick={toggleCollapsed} to={`/relatorio/alunosescolasrotas`}>
                  Alunos X Escolas X Rotas
                </NavLinkStyle>
              </Menu.Item>
              <Menu.Item
                icon={
                  !topMenu && (
                    <NavLink className="menuItem-iocn" to={`/relatorio/alunosrotas`}>
                      <FeatherIcon icon="check-square" />
                    </NavLink>
                  )
                }
                key="/relatorio/alunosrotas"
              >
                <NavLinkStyle onClick={toggleCollapsed} to={`/relatorio/alunosrotas`}>
                  Alunos X Rotas
                </NavLinkStyle>
              </Menu.Item>
              <Menu.Item
                icon={
                  !topMenu && (
                    <NavLink className="menuItem-iocn" to={`/relatorio/demandas`}>
                      <FeatherIcon icon="check-square" />
                    </NavLink>
                  )
                }
                key="/relatorio/demandas"
              >
                <NavLinkStyle onClick={toggleCollapsed} to={`/relatorio/demandas`}>
                  Demandas
                </NavLinkStyle>
              </Menu.Item>
              <Menu.Item
                icon={
                  !topMenu && (
                    <NavLink className="menuItem-iocn" to={`/relatorio/rotas`}>
                      <FeatherIcon icon="check-square" />
                    </NavLink>
                  )
                }
                key="/relatorio/rotas"
              >
                <NavLinkStyle onClick={toggleCollapsed} to={`/relatorio/rotas`}>
                  Rotas
                </NavLinkStyle>
              </Menu.Item>
            </SubMenu>
          )}

          <SubMenu key="pessoas" icon={!topMenu && <FeatherIcon icon="check-square" />} title="Cadastro Pessoas">
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/pessoas`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/pessoas"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/pessoas`}>
                Usuários
              </NavLinkStyle>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/escolascargos`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/escolascargos"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/escolascargos`}>
                Cargos
              </NavLinkStyle>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/usuarios`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/usuarios"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/usuarios`}>
                Usuários Admin
              </NavLinkStyle>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/usuariosapps`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/usuariosapps"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/usuariosapps`}>
                Usuários App
              </NavLinkStyle>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/sexos`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/sexos"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/sexos`}>
                Sexos
              </NavLinkStyle>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/nacionalidades`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/nacionalidades"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/nacionalidades`}>
                Nacionalidades
              </NavLinkStyle>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/estados-civis`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/estados-civis"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/estados-civis`}>
                Estados Civis
              </NavLinkStyle>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/proprietarios`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/proprietarios"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/proprietarios`}>
                Proprietários
              </NavLinkStyle>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/estudantes`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/estudantes"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/estudantes`}>
                Estudantes
              </NavLinkStyle>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/monitores`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/monitores"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/monitores`}>
                Monitores
              </NavLinkStyle>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/condutores`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/condutores"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/condutores`}>
                Condutores
              </NavLinkStyle>
            </Menu.Item>
          </SubMenu>
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`/cidades`}>
                  <FeatherIcon icon="check-square" />
                </NavLink>
              )
            }
            key="/cidades"
          >
            <NavLink onClick={toggleCollapsed} to={`/cidades`}>
              Cidades
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`/bancos`}>
                  <FeatherIcon icon="check-square" />
                </NavLink>
              )
            }
            key="/bancos"
          >
            <NavLink onClick={toggleCollapsed} to={`/bancos`}>
              Bancos
            </NavLink>
          </Menu.Item>

          <SubMenu key="escolas" icon={!topMenu && <FeatherIcon icon="check-square" />} title="Escolas">
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/escolas`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/escolas"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/escolas`}>
                Escolas
              </NavLinkStyle>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/escolascargos`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/escolascargos"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/escolascargos`}>
                Escolas Cargos
              </NavLinkStyle>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/escolasesferas`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/escolasesferas"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/escolasesferas`}>
                Escolas Esferas
              </NavLinkStyle>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/escolasgraus`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/escolasgraus"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/escolasgraus`}>
                Escolas Graus
              </NavLinkStyle>
            </Menu.Item>
          </SubMenu>

          <SubMenu key="empresas" icon={!topMenu && <FeatherIcon icon="check-square" />} title="Empresas">
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/empresas`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/empresas"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/empresas`}>
                Empresas
              </NavLinkStyle>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/contratos`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/contratos"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/contratos`}>
                Contratos
              </NavLinkStyle>
            </Menu.Item>

            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/veiculos`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/veiculos"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/veiculos`}>
                Veiculos
              </NavLinkStyle>
            </Menu.Item>

            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/rotas`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/rotas"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/rotas`}>
                Rotas
              </NavLinkStyle>
            </Menu.Item>
          </SubMenu>
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`/operadoras`}>
                  <FeatherIcon icon="check-square" />
                </NavLink>
              )
            }
            key="/operadoras"
          >
            <NavLink onClick={toggleCollapsed} to={`/operadoras`}>
              Operadoras
            </NavLink>
          </Menu.Item>
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`/gestores`}>
                  <FeatherIcon icon="check-square" />
                </NavLink>
              )
            }
            key="/gestores"
          >
            <NavLink onClick={toggleCollapsed} to={`/gestores`}>
              Gestores
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`/ocorrencias`}>
                  <FeatherIcon icon="check-square" />
                </NavLink>
              )
            }
            key="/ocorrencias"
          >
            <NavLink onClick={toggleCollapsed} to={`/ocorrencias`}>
              Ocorrências
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`/visualizacaodiario`}>
                  <FeatherIcon icon="check-square" />
                </NavLink>
              )
            }
            key="/visualizacaodiario"
          >
            <NavLink onClick={toggleCollapsed} to={`/visualizacaodiario`}>
              Diario
            </NavLink>
          </Menu.Item>
        </MenuStyle>
      );

    case 'ADMIN':
      return (
        <MenuStyle
          mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
          theme="light"
          defaultSelectedKeys={[location.pathname]}
          onOpenChange={onOpenChange}
          onClick={onClick}
          openKeys={openKeys}
        >
          {!topMenu && <p className="sidebar-nav-title">Principal</p>}

          <SubMenu key="pessoas" icon={!topMenu && <FeatherIcon icon="check-square" />} title="Cadastro Pessoas">
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/pessoas`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/pessoas"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/pessoas`}>
                Usuários
              </NavLinkStyle>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/escolascargos`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/escolascargos"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/escolascargos`}>
                Cargos
              </NavLinkStyle>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/usuariosapps`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/usuariosapps"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/usuariosapps`}>
                Usuários App
              </NavLinkStyle>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/sexos`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/sexos"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/sexos`}>
                Sexos
              </NavLinkStyle>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/nacionalidades`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/nacionalidades"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/nacionalidades`}>
                Nacionalidades
              </NavLinkStyle>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/estados-civis`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/estados-civis"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/estados-civis`}>
                Estados Civis
              </NavLinkStyle>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/proprietarios`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/proprietarios"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/proprietarios`}>
                Proprietários
              </NavLinkStyle>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/estudantes`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/estudantes"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/estudantes`}>
                Estudantes
              </NavLinkStyle>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/monitores`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/monitores"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/monitores`}>
                Monitores
              </NavLinkStyle>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/condutores`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/condutores"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/condutores`}>
                Condutores
              </NavLinkStyle>
            </Menu.Item>
          </SubMenu>
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`/cidades`}>
                  <FeatherIcon icon="check-square" />
                </NavLink>
              )
            }
            key="/cidades"
          >
            <NavLink onClick={toggleCollapsed} to={`/cidades`}>
              Cidades
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`/bancos`}>
                  <FeatherIcon icon="check-square" />
                </NavLink>
              )
            }
            key="/bancos"
          >
            <NavLink onClick={toggleCollapsed} to={`/bancos`}>
              Bancos
            </NavLink>
          </Menu.Item>

          <SubMenu key="escolas" icon={!topMenu && <FeatherIcon icon="check-square" />} title="Escolas">
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/escolas`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/escolas"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/escolas`}>
                Escolas
              </NavLinkStyle>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/escolascargos`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/escolascargos"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/escolascargos`}>
                Escolas Cargos
              </NavLinkStyle>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/escolasesferas`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/escolasesferas"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/escolasesferas`}>
                Escolas Esferas
              </NavLinkStyle>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/escolasgraus`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/escolasgraus"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/escolasgraus`}>
                Escolas Graus
              </NavLinkStyle>
            </Menu.Item>
          </SubMenu>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`/escolas`}>
                  <FeatherIcon icon="check-square" />
                </NavLink>
              )
            }
            key="/escolas"
          >
            <NavLink onClick={toggleCollapsed} to={`/escolas`}>
              Escolas
            </NavLink>
          </Menu.Item>

          <SubMenu key="empresas" icon={!topMenu && <FeatherIcon icon="check-square" />} title="Empresas">
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/empresas`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/empresas"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/empresas`}>
                Empresas
              </NavLinkStyle>
            </Menu.Item>

            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`/rotas`}>
                    <FeatherIcon icon="check-square" />
                  </NavLink>
                )
              }
              key="/rotas"
            >
              <NavLinkStyle onClick={toggleCollapsed} to={`/rotas`}>
                Rotas
              </NavLinkStyle>
            </Menu.Item>
          </SubMenu>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`/ocorrencias`}>
                  <FeatherIcon icon="check-square" />
                </NavLink>
              )
            }
            key="/ocorrencias"
          >
            <NavLink onClick={toggleCollapsed} to={`/ocorrencias`}>
              Ocorrências
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`/visualizacaodiario`}>
                  <FeatherIcon icon="check-square" />
                </NavLink>
              )
            }
            key="/visualizacaodiario"
          >
            <NavLink onClick={toggleCollapsed} to={`/visualizacaodiario`}>
              Diario
            </NavLink>
          </Menu.Item>
        </MenuStyle>
      );

    case 'ESCOLA':
      return (
        <MenuStyle
          mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
          theme="light"
          defaultSelectedKeys={[location.pathname]}
          onOpenChange={onOpenChange}
          onClick={onClick}
          openKeys={openKeys}
        >
          {!topMenu && <p className="sidebar-nav-title">Principal</p>}

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`/estudantes`}>
                  <FeatherIcon icon="check-square" />
                </NavLink>
              )
            }
            key="/estudantes"
          >
            <NavLink onClick={toggleCollapsed} to={`/estudantes`}>
              Estudantes
            </NavLink>
          </Menu.Item>
        </MenuStyle>
      );
    default:
      return (
        <MenuStyle
          mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
          theme="light"
          defaultSelectedKeys={[location.pathname]}
          onOpenChange={onOpenChange}
          onClick={onClick}
          openKeys={openKeys}
        >
          {!topMenu && <p className="sidebar-nav-title">Principal</p>}

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`/cidades`}>
                  <FeatherIcon icon="check-square" />
                </NavLink>
              )
            }
            key="/cidades"
          >
            <NavLink onClick={toggleCollapsed} to={`/cidades`}>
              Cidades
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`/visualizacaodiario`}>
                  <FeatherIcon icon="check-square" />
                </NavLink>
              )
            }
            key="/visualizacaodiario"
          >
            <NavLink onClick={toggleCollapsed} to={`/visualizacaodiario`}>
              Visualização Diario
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`/usuariosapps`}>
                  <FeatherIcon icon="check-square" />
                </NavLink>
              )
            }
            key="/usuariosapps"
          >
            <NavLink onClick={toggleCollapsed} to={`/usuariosapps`}>
              UsuariosApps
            </NavLink>
          </Menu.Item>
        </MenuStyle>
      );
  }
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
