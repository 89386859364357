import { ColumnsType } from 'antd/lib/table';
import { IVeiculoProps } from '../../../veiculos/types';

interface ITableColumnControllerProps {
  dataList?: IVeiculoProps[];
}

export function TableColumnController({ dataList }: ITableColumnControllerProps) {

  const columns: ColumnsType<IVeiculoProps> = [
    {
      title: 'Placa',
      dataIndex: 'siglaa',
      sorter: true,
      render: (_, data) => data.placa,
    },
    {
      title: 'Modelo',
      dataIndex: 'siglaw',
      sorter: true,
      render: (_, data) => data.modelo,
    },
  ];

  return { columns, data: dataList };
}
