import { ColumnsType } from 'antd/lib/table';

import { IRotaProps } from '../../types';
import { useHistory } from 'react-router-dom';

interface ITableColumnControllerProps {
  handleDeleteFunction: (rotaId: string) => Promise<void>;
  handleRestoreFunction: (rotaId: string) => Promise<void>;
  refetch: () => void;
  dataList?: IRotaProps[];
}

export function TableColumnController({ dataList }: ITableColumnControllerProps) {
  const history = useHistory();

  const columns: ColumnsType<any> = [
    {
      title: 'Nome',
      dataIndex: 'siglaa',
      sorter: true,
      render: (_, data) => data.pessoa.nome,
    },
    {
      title: 'Identificação da rota',
      dataIndex: 'siglaw',
      sorter: true,
      render: (_, data) => data.idRotaTrajeto.idRota.sigla,
    },
   
  ];

  return { columns, data: dataList };
}
