import React, { useRef, useState } from 'react';

import { Row, Col, Button, Input, Form as AntdForm } from 'antd';
import { CardWrapper, FormWrapper } from '../styles';
import { api } from '../../../services/api';
import { getSubDomain } from '../../../hooks/getSubDomain';
import { SelectSegmento } from '../../../components/Select';
import { UploadComponent } from '../../../components/UploadComponent';
import { IHandleSubmitProps } from '../types';
import { UploadFile } from 'antd/lib/upload/interface';
import toast from 'react-hot-toast';
import { MultiSelect } from '../../../components/MultiSelect';
import { dados } from '../../../utility/env';
import { SelectPessoa } from '../../../components/Select/SelectPessoa';
import { SelectBanco } from '../../../components/Select/SelectBanco';
import { SelectProprietario } from '../../../components/Select/SelectProprietario';
import { SelectContrato } from '../../../components/Select/SelectContrato';
import { SelectCondutor } from '../../../components/Select/SelectCondutor';
import { SelectMonitor } from '../../../components/Select/SelectMonitor';
import { SelectVeiculo } from '../../../components/Select/SelectVeiculo';
import { SelectCidade } from '../../../components/Select/SelectCidade';
import { useAuth } from '../../../hooks/useAuth';

interface IFormInitialFields {
  idCidade: string;
  sigla: string;
  idContrato: string;
  idCondutor: string;
  idRota: string;
  idMonitor: string;
  distancia: string;
  valorKM: string;
  valorKMRecebido: string;
  idVeiculo: string;
}

interface IFormComponent {
  formType: 'put' | 'post' | 'view';
  initialFields?: IFormInitialFields;
  refetch: () => void;
  rotaId?: string;
  setModalIsOpen: (event: boolean) => void;
}

export function Form({
  formType,
  setModalIsOpen,
  refetch,
  rotaId,
  initialFields = {
    idCidade: '',
    sigla: '',
    idVeiculo: '',
    idContrato: '',
    idCondutor: '',
    idRota: '',
    idMonitor: '',
    distancia: '',
    valorKM: '',
    valorKMRecebido: '',
  },
}: IFormComponent) {
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef<any>(null);

  function clearFieldsAndCloseModal() {
    setModalIsOpen(false);
  }

  async function handleSubmit(data: IHandleSubmitProps) {
    setIsLoading(true);

    const formData = {
      ...data,
    };

    try {
      if (formType === 'post') {
        await api.post(getSubDomain() + 'rotas', formData);
      } else {
        if (rotaId) await api.put(getSubDomain() + `rotas/${rotaId}`, formData);
      }

      setModalIsOpen(false);

      formRef.current.resetFields();
    } catch (err) {
      console.log(err);
    }

    setIsLoading(false);
    refetch();
  }

  const { activeCity } = useAuth();

  return (
    <FormWrapper>
      <AntdForm
        name="basic"
        onFinish={handleSubmit}
        style={{ padding: '30px' }}
        layout="vertical"
        initialValues={{ ...initialFields, idCidade: initialFields.idCidade || activeCity?.toString() }}
        ref={formRef}
      >
        <Row gutter={40}>
          <Col span={12}>
            <CardWrapper type="inner" title="Informações Gerais">
              <SelectCidade
                name="idCidade"
                defaultValue={initialFields.idCidade}
                disabled={formType === 'view'}
                title="Cidade"
                fetchUrl="cidades"
              />

              <AntdForm.Item label="Sigla" name="sigla" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <Input placeholder="" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>

              <SelectContrato
                name="idContrato"
                disabled={formType === 'view' ? true : false}
                title="Contrato"
                defaultValue={initialFields.idContrato}
                fetchUrl="contratos"
              />

              <SelectCondutor
                name="idCondutor"
                title="Condutor"
                defaultValue={initialFields.idCondutor}
                disabled={formType === 'view' ? true : false}
                fetchUrl="condutors"
              />
            </CardWrapper>
          </Col>

          <Col span={12}>
            <CardWrapper type="inner" title="Dados Diversos">
              <SelectVeiculo
                name="idVeiculo"
                title="Veículo"
                defaultValue={initialFields.idVeiculo}
                disabled={formType === 'view' ? true : false}
                fetchUrl="veiculos"
              />

              <SelectMonitor
                name="idMonitor"
                defaultValue={initialFields.idMonitor}
                title="Monitor"
                disabled={formType === 'view' ? true : false}
                fetchUrl="monitors"
              />

              <AntdForm.Item
                label="Distância"
                name="distancia"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input placeholder="" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>

              <AntdForm.Item label="Valor KM" name="valorKM" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <Input placeholder="" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>

              <AntdForm.Item
                label="Valor KM Recebido"
                name="valorKMRecebido"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input placeholder="" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>
            </CardWrapper>
          </Col>
        </Row>
        {formType == 'view' ? (
          <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
            <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
              Fechar
            </Button>
          </footer>
        ) : (
          <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
            <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
              Voltar
            </Button>

            <Button type="primary" htmlType="submit" loading={isLoading}>
              Salvar
            </Button>
          </footer>
        )}
      </AntdForm>
    </FormWrapper>
  );
}
