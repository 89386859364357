import React, { useState } from "react";
// import { Modal } from "antd"; 
import FeatherIcon from "feather-icons-react";
import moment from "moment";
import { Button } from "../../../../components/buttons/buttons";
import { Modal } from "antd";
import { Form } from "../OperadoraForm";
import { useFetch } from "../../../../hooks/useFetch";
import { format } from "date-fns";

interface ITableButtonEditProps {
  operadoraId: string;
  refetch: () => void;
  data: IFetchProps;
}

interface IFetchProps {
  idGestor: string;
  nome: string;
  endereco: string;
  numero: string;
  bairro: string;
  cidade: string;
  complemento: string;
  cep: string;
  tel1: string;
  tel2: string;
  email: string;
  sigla: string;
  site: string;
  logomarca: string;
  cnpj: string;
  ie: string;
uf: string;

  
}

export function TableButtonView({ operadoraId, refetch, data }: ITableButtonEditProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <Button className="btn-icon" type="info" onClick={() => setModalIsOpen(true)} to="#" shape="circle">
        <FeatherIcon icon="search" size={16} />
      </Button>

      <Modal
        title="Visualizar"
        visible={modalIsOpen}
        onCancel={() => setModalIsOpen(false)}
        confirmLoading={true}
        footer={null}
        maskClosable={false}
        width={1200}
        style={{ marginTop: -60 }}
        bodyStyle={{ padding: 0 }}
      >
        <Form
          formType="view"
          operadoraId={operadoraId}
          initialFields={{
            idGestor: String(data.idGestor),
            cidade: data.cidade,
            email: data.email,
            site: data.site,
            sigla: data.sigla,
            nome: data.nome,
            endereco: data.endereco,
            numero: data.numero,
            bairro: data.bairro,
            complemento: data.complemento,
            cep: data.cep,
            tel1: data.tel1,
            tel2: data.tel2,
            logomarca: data.logomarca,
            cnpj: data.cnpj,
            ie: data.ie,
            uf: data.uf,
          }}
          setModalIsOpen={setModalIsOpen}
          refetch={refetch}
        />
      </Modal>
    </>
  );
}