import React, { useState } from "react";
import { Modal } from "antd"; 
import FeatherIcon from "feather-icons-react";

import { Button } from "../../../../components/buttons/buttons";

interface ITableButtonRestoreProps {
  contactId: string;
  handleRestoreFunction: (contactId: string) => Promise<void>;
}

export function TableButtonRestore({ contactId, handleRestoreFunction }: ITableButtonRestoreProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenModal = () => setModalIsOpen(true);
  const handleCloseModal = () => setModalIsOpen(false);

  async function handleRestore() {
    setIsLoading(true);
    await handleRestoreFunction(contactId);
    handleCloseModal();
    setIsLoading(false);
  }

  return (
    <>
      <Button 
        className="btn-icon" 
        type="danger" 
        shape="circle" 
        onClick={handleOpenModal}
      >
        <FeatherIcon icon="eye" size={16} />
      </Button>

      <Modal
        title="Ativar"
        visible={modalIsOpen}
        onOk={handleRestore}
        onCancel={handleCloseModal}
        okText="Confirmar"
        okType="danger"
        confirmLoading={isLoading}
      >
        <p>Deseja mesmo ativar esse item?</p>
      </Modal>
    </>
  );
}
