import React, { useRef, useState } from 'react';
import { getSubDomain } from '../../../hooks/getSubDomain';
import { Row, Col, Button, Input, Form as AntdForm, DatePicker } from 'antd';
import { CardWrapper, FormWrapper } from '../styles';
import { api } from '../../../services/api';
import { SelectSegmento } from '../../../components/Select';
import { UploadComponent } from '../../../components/UploadComponent';
import { IHandleSubmitProps } from '../types';
import { UploadFile } from 'antd/lib/upload/interface';
import toast from 'react-hot-toast';
import { MultiSelect } from '../../../components/MultiSelect';
import { dados } from '../../../utility/env';
import { SelectPessoa } from '../../../components/Select/SelectPessoa';
import { SelectBanco } from '../../../components/Select/SelectBanco';
import { SelectProprietario } from '../../../components/Select/SelectProprietario';
import moment, { Moment } from 'moment';

interface IFormInitialFields {
  idProprietario: string;
  marca: string;
  modelo: string;
  anoFabricacao: string;
  anoModelo: string;
  renavam: string;
  chassi: string;
  potencia: string;
  placa: string;
  idGetrak: string;
  cilindrada: string;
  corPredominante: string;
  capacidade: string;
  usoInicio: moment.Moment;
  usoFim: moment.Moment;
  crlv: string;
  crlvVencimento: moment.Moment;
  vistoria: string;
  vistoriaVencimento: moment.Moment;
  seguroEstudante: string;
  seguroEstudanteVencimento: moment.Moment;
  termoUso: string;
}

interface IFormComponent {
  formType: 'put' | 'post' | 'view';
  initialFields?: IFormInitialFields;
  refetch: () => void;
  veiculoId?: string;
  setModalIsOpen: (event: boolean) => void;
}

export function Form({
  formType,
  setModalIsOpen,
  refetch,
  veiculoId,
  initialFields = {
    idProprietario: '',
    marca: '',
    modelo: '',
    anoFabricacao: '',
    anoModelo: '',
    renavam: '',
    chassi: '',
    idGetrak: '',
    potencia: '',
    placa: '',
    cilindrada: '',
    corPredominante: '',
    capacidade: '',
    usoInicio: moment(),
    usoFim: moment(),
    crlv: '',
    crlvVencimento: moment(),
    vistoria: '',
    vistoriaVencimento: moment(),
    seguroEstudante: '',
    seguroEstudanteVencimento: moment(),
    termoUso: '',
  },
}: IFormComponent) {
  const [isLoading, setIsLoading] = useState(false);

  const [fileListCrlv, setFileListCrlv] = useState<UploadFile[]>(() => {
    if (initialFields.crlv) {
      return [
        {
          uid: initialFields.crlv,
          name: initialFields.crlv,
          status: 'done',
          url: dados.imgbaseurl + initialFields.crlv,
        },
      ];
    } else return [] as UploadFile[];
  });

  const [fileListVistoria, setFileListVistoria] = useState<UploadFile[]>(() => {
    if (initialFields.vistoria) {
      return [
        {
          uid: initialFields.vistoria,
          name: initialFields.vistoria,
          status: 'done',
          url: dados.imgbaseurl + initialFields.vistoria,
        },
      ];
    } else return [] as UploadFile[];
  });

  const [fileListSeguroEstudante, setFileListSeguroEstudante] = useState<UploadFile[]>(() => {
    if (initialFields.seguroEstudante) {
      return [
        {
          uid: initialFields.seguroEstudante,
          name: initialFields.seguroEstudante,
          status: 'done',
          url: dados.imgbaseurl + initialFields.seguroEstudante,
        },
      ];
    } else return [] as UploadFile[];
  });

  const [fileListTermoUso, setFileListTermoUso] = useState<UploadFile[]>(() => {
    if (initialFields.termoUso) {
      return [
        {
          uid: initialFields.termoUso,
          name: initialFields.termoUso,
          status: 'done',
          url: dados.imgbaseurl + initialFields.termoUso,
        },
      ];
    } else return [] as UploadFile[];
  });

  const formRef = useRef<any>(null);

  function clearFieldsAndCloseModal() {
    setModalIsOpen(false);
  }

  async function handleSubmit(data: IHandleSubmitProps) {
    setIsLoading(true);

    if (fileListCrlv.length <= 0) {
      toast.error('É necessário indexar um arquivo para CRLV');
      setIsLoading(false);
      return;
    }

    if (fileListVistoria.length <= 0) {
      toast.error('É necessário indexar um arquivo para Vistoria');
      setIsLoading(false);
      return;
    }

    if (fileListSeguroEstudante.length <= 0) {
      toast.error('É necessário indexar um arquivo para o Seguro Estudante');
      setIsLoading(false);
      return;
    }

    if (fileListTermoUso.length <= 0) {
      toast.error('É necessário indexar um arquivo para o Termo de Uso');
      setIsLoading(false);
      return;
    }

    const formData = {
      ...data,
      crlv: fileListCrlv[0].response ? fileListCrlv[0].response.filename : fileListCrlv[0].name,
      vistoria: fileListVistoria[0].response ? fileListVistoria[0].response.filename : fileListVistoria[0].name,

      seguroEstudante: fileListSeguroEstudante[0].response
        ? fileListSeguroEstudante[0].response.filename
        : fileListSeguroEstudante[0].name,
      termoUso: fileListTermoUso[0].response ? fileListTermoUso[0].response.filename : fileListTermoUso[0].name,
    };

    try {
      if (formType === 'post') {
        await api.post(getSubDomain() + 'veiculos', formData);
      } else {
        if (veiculoId) await api.put(getSubDomain() + `veiculos/${veiculoId}`, formData);
      }

      setModalIsOpen(false);

      formRef.current.resetFields();
      setFileListCrlv([]);

      setFileListVistoria([]);
      setFileListSeguroEstudante([]);
      setFileListTermoUso([]);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
    refetch();
  }

  return (
    <FormWrapper>
      <AntdForm
        name="basic"
        onFinish={handleSubmit}
        style={{ padding: '30px' }}
        layout="vertical"
        initialValues={initialFields}
        ref={formRef}
      >
        <Row gutter={40}>
          <Col span={12}>
            <CardWrapper type="inner" title="Informações Gerais">
              <SelectProprietario
                name="idProprietario"
                defaultValue={initialFields.idProprietario}
                title="Proprietário"
                disabled={formType === 'view' ? true : false}
                fetchUrl="proprietarios"
              />

              <AntdForm.Item
                label="Marca"
                name="marca"
                rules={[
                  { required: true, message: 'Campo obrigatório' },
                  { max: 15, message: 'O campo deve conter no máximo 415 caracteres.' },
                ]}
              >
                <Input placeholder="" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>

              <AntdForm.Item
                label="Placa"
                name="placa"
                rules={[
                  { required: true, message: 'Campo obrigatório' },
                  { max: 8, message: 'O campo deve conter no máximo 8 caracteres.' },
                ]}
              >
                <Input placeholder="" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>

              <AntdForm.Item label="Id Veículo (Getrak)" name="idGetrak">
                <Input placeholder="" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>

              <AntdForm.Item
                label="Modelo"
                name="modelo"
                rules={[
                  { required: true, message: 'Campo obrigatório' },
                  { max: 45, message: 'O campo deve conter no máximo 45 caracteres.' },
                ]}
              >
                <Input placeholder="" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>

              <AntdForm.Item
                label="Ano Modelo"
                name="anoModelo"
                rules={[
                  { required: true, message: 'Campo obrigatório' },
                  { max: 4, message: 'O campo deve conter no máximo 4 caracteres.' },
                ]}
              >
                <Input placeholder="" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>

              <AntdForm.Item
                label="Ano Fabricação"
                name="anoFabricacao"
                rules={[
                  { required: true, message: 'Campo obrigatório' },
                  { max: 4, message: 'O campo deve conter no máximo 4 caracteres.' },
                ]}
              >
                <Input placeholder="" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>

              <AntdForm.Item
                label="Renavam"
                name="renavam"
                rules={[
                  { required: true, message: 'Campo obrigatório' },
                  { max: 11, message: 'O campo deve conter no máximo 11 caracteres.' },
                ]}
              >
                <Input placeholder="" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>

              <AntdForm.Item
                label="Chassi"
                name="chassi"
                rules={[
                  { required: true, message: 'Campo obrigatório' },
                  { max: 17, message: 'O campo deve conter no máximo 17 caracteres.' },
                ]}
              >
                <Input placeholder="" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>
              <AntdForm.Item
                label="Potência"
                name="potencia"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input placeholder="" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>

              <AntdForm.Item
                label="Cilindrada"
                name="cilindrada"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input placeholder="" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>

              <AntdForm.Item
                label="Cor Predominante"
                name="corPredominante"
                rules={[
                  { required: true, message: 'Campo obrigatório' },
                  { max: 30, message: 'O campo deve conter no máximo 30 caracteres.' },
                ]}
              >
                <Input placeholder="" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>

              <AntdForm.Item
                label="Capacidade"
                name="capacidade"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input placeholder="" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>
            </CardWrapper>
          </Col>

          <Col span={12}>
            <CardWrapper type="inner" title="Dados Diversos">
              <AntdForm.Item
                label="Data Início de Uso"
                name="usoInicio"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder="Selecione uma Data"
                  disabled={formType === 'view' ? true : false}
                />
              </AntdForm.Item>
              <AntdForm.Item
                label="Data Fim de Uso"
                name="usoFim"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder="Selecione uma Data"
                  disabled={formType === 'view' ? true : false}
                />
              </AntdForm.Item>

              <AntdForm.Item label="CRLV" name="crlv">
                <UploadComponent
                  maxCount={1}
                  disabled={formType === 'view' ? true : false}
                  fileList={fileListCrlv}
                  setFileList={setFileListCrlv}
                />
              </AntdForm.Item>

              <AntdForm.Item
                label="Vencimento CRLV"
                name="crlvVencimento"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder="Selecione uma Data"
                  disabled={formType === 'view' ? true : false}
                />
              </AntdForm.Item>

              <AntdForm.Item label="Vistoria" name="vistoria">
                <UploadComponent
                  maxCount={1}
                  disabled={formType === 'view' ? true : false}
                  fileList={fileListVistoria}
                  setFileList={setFileListVistoria}
                />
              </AntdForm.Item>

              <AntdForm.Item
                label="Vencimento Vistoria"
                name="vistoriaVencimento"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder="Selecione uma Data"
                  disabled={formType === 'view' ? true : false}
                />
              </AntdForm.Item>

              <AntdForm.Item label="Seguro Estudante" name="seguroEstudante">
                <UploadComponent
                  maxCount={1}
                  disabled={formType === 'view' ? true : false}
                  fileList={fileListSeguroEstudante}
                  setFileList={setFileListSeguroEstudante}
                />
              </AntdForm.Item>

              <AntdForm.Item
                label="Vencimento Seguro Estudante"
                name="seguroEstudanteVencimento"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder="Selecione uma Data"
                  disabled={formType === 'view' ? true : false}
                />
              </AntdForm.Item>

              <AntdForm.Item label="Termo de Uso" name="termoUso">
                <UploadComponent
                  maxCount={1}
                  disabled={formType === 'view' ? true : false}
                  fileList={fileListTermoUso}
                  setFileList={setFileListTermoUso}
                />
              </AntdForm.Item>
            </CardWrapper>
          </Col>
        </Row>
        {formType == 'view' ? (
          <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
            <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
              Fechar
            </Button>
          </footer>
        ) : (
          <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
            <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
              Voltar
            </Button>

            <Button type="primary" htmlType="submit" loading={isLoading}>
              Salvar
            </Button>
          </footer>
        )}
      </AntdForm>
    </FormWrapper>
  );
}
