import { ColumnsType } from 'antd/lib/table';

import { IRotaProps } from '../../types';

interface ITableColumnControllerProps {
  handleDeleteFunction: (rotaId: string) => Promise<void>;
  handleRestoreFunction: (rotaId: string) => Promise<void>;
  refetch: () => void;
  dataList?: IRotaProps[];
}

export function TableColumnController({ dataList }: ITableColumnControllerProps) {
  const columns: ColumnsType<any> = [
    {
      title: 'Nome do aluno',
      dataIndex: 'siglaa',
      sorter: true,
      render: (_, data) => data.idPessoa.nome,
    },
    {
      title: 'Nome da escola',
      dataIndex: 'siglaa',
      sorter: true,
      render: (_, data) => data.idEscola.nome,
    },
    {
      title: 'Esfera escolar',
      dataIndex: 'siglaa',
      sorter: true,
      render: (_, data) => data.idEscola.idEsfera.esfera,
    },
    {
      title: 'Grau escolar',
      dataIndex: 'siglaa',
      sorter: true,
      render: (_, data) => data.idEscola.grauEscola,
    },
  ];

  return { columns, data: dataList };
}
