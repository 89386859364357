import React from 'react';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';

import { IVeiculoProps } from '../../types';
import { TableButtonDelete } from './TableButtonDelete';
import { TableButtonEdit } from './TableButtonEdit';
import { TableButtonView } from './TableButtonView';
import { TableButtonRestore } from './TableButtonRestore';

interface ITableColumnControllerProps {
  handleDeleteFunction: (veiculoId: string) => Promise<void>;
  handleRestoreFunction: (veiculoId: string) => Promise<void>;
  refetch: () => void;
  dataList?: IVeiculoProps[];
}

export function TableColumnController({
  handleDeleteFunction,
  handleRestoreFunction,
  refetch,
  dataList,
}: ITableColumnControllerProps) {
  const columns: ColumnsType<IVeiculoProps> = [
    {
      title: 'Veículo',
      dataIndex: 'veiculo',
      sorter: true,
    },

    {
      title: 'Placa',
      dataIndex: 'placa',
      sorter: true,
    },

    {
      title: 'Ações',
      dataIndex: 'actions',
      render: (_, item) => {
        return (
          <div className="table-actions">
            {item.status === 0 ? (
              <TableButtonRestore handleRestoreFunction={handleRestoreFunction} contactId={String(item.id)} />
            ) : (
              <>
                <TableButtonView veiculoId={String(item.id)} refetch={refetch} data={item} />
                <TableButtonEdit veiculoId={String(item.id)} refetch={refetch} data={item} />

                <TableButtonDelete handleDeleteFunction={handleDeleteFunction} veiculoId={String(item.id)} />
              </>
            )}
          </div>
        );
      },
    },
  ];

  const data: any[] = [];

  dataList?.forEach(item => {
    data.push({
      key: item.id,
      id: item.id,
      veiculo: item.modelo + ' (' + item.marca + ') ' + ' (Ano ' + item.anoModelo + ')',
      createdAt: moment(item.createdAt),
      idProprietario: item.idProprietario,
      marca: item.marca,
      modelo: item.modelo,
      anoFabricacao: item.anoFabricacao,
      anoModelo: item.anoModelo,
      renavam: item.renavam,
      placa: item.placa,
      idGetrak: item.idGetrak,
      chassi: item.chassi,
      potencia: item.potencia,
      cilindrada: item.cilindrada,
      corPredominante: item.corPredominante,
      capacidade: item.capacidade,
      usoInicio: moment(item.usoInicio),
      usoFim: moment(item.usoFim),
      crlv: item.crlv,
      crlvVencimento: moment(item.crlvVencimento),
      vistoria: item.vistoria,
      vistoriaVencimento: moment(item.vistoriaVencimento),
      seguroEstudante: item.seguroEstudante,
      seguroEstudanteVencimento: moment(item.seguroEstudanteVencimento),
      termoUso: item.termoUso,
      status: item.status,
    });
  });

  return { columns, data };
}
