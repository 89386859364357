export function getSubDomain() {
  const hostName = window.location.host.split('.');
  console.log(window.location.host.split('.')[0]);

  if (window.location.host.split('.')[0] == 'localhost:3000') {
    const subdomain = 'spat';
    return `/rotas_${hostName.length > 1 ? subdomain : 'spat'}/`;
  } else {
    const subdomain = window.location.host.split('.')[0];
    return `/rotas_${hostName.length > 1 ? subdomain : 'spat'}/`;
  }
}
