import React, { useRef, useState } from "react";

import { Row, Col, Button, Input, Form as AntdForm, DatePicker } from "antd";
import { CardWrapper, FormWrapper } from "../styles";
import { api } from "../../../services/api";
import { getSubDomain } from "../../../hooks/getSubDomain";
import { SelectSegmento } from "../../../components/Select";
import { UploadComponent } from "../../../components/UploadComponent";
import { IHandleSubmitProps } from "../types";
import { UploadFile } from "antd/lib/upload/interface";
import toast from "react-hot-toast";
import { MultiSelect } from "../../../components/MultiSelect";
import { dados } from "../../../utility/env";
import { SelectPessoa } from "../../../components/Select/SelectPessoa";
import { SelectBanco } from "../../../components/Select/SelectBanco";
import { SelectProprietario } from "../../../components/Select/SelectProprietario";
import moment, { Moment } from "moment";
import { SelectOperadora } from "../../../components/Select/SelectOperadora";

interface IFormInitialFields {
  idProprietario: string;
  idOperadora: string;
  numero: string;
  contrato: string;
  contratoInicio: moment.Moment;
  contratoFim: moment.Moment;
  

}

interface IFormComponent {
  formType: "put" | "post" | "view",
  initialFields?: IFormInitialFields;
  refetch: () => void;
  veiculoId?: string;
  setModalIsOpen: (event: boolean) => void;
}

export function Form({ 
  formType,
  setModalIsOpen, 
  refetch, 
  veiculoId,
  initialFields = { 
    
    idProprietario: "",
    idOperadora: "",
    numero:"",
    contrato: "",
    contratoInicio: moment(),
    contratoFim:  moment(),
   
    
  
  } 
}: IFormComponent) {
  const [isLoading, setIsLoading] = useState(false);



  const [fileListcontrato, setFileListcontrato] = useState<UploadFile[]>(() => {
    if(initialFields.contrato) {
      return [{
        uid: initialFields.contrato,
        name: initialFields.contrato,
        status: 'done',
        url: dados.imgbaseurl + initialFields.contrato,
      }]
    }

    else return [] as UploadFile[];
  })

  const formRef = useRef<any>(null);

  function clearFieldsAndCloseModal() {
    setModalIsOpen(false);
  }

  async function handleSubmit(data: IHandleSubmitProps) {
    setIsLoading(true);

   

    if(fileListcontrato.length <= 0) {
      toast.error("É necessário indexar um Contrato");
      setIsLoading(false);
      return;
    }

    const formData = {
      ...data,
      contrato: fileListcontrato[0].response? fileListcontrato[0].response.filename : fileListcontrato[0].name,
    
        }

    try {
      if(formType === "post") {
        await api.post(getSubDomain() + "contratos", formData);
      } else {
        if(veiculoId) await api.put(getSubDomain() + `contratos/${veiculoId}`, formData);
      }

      setModalIsOpen(false);
       
      formRef.current.resetFields()
    
    } catch(err) {
      console.log(err)
    }
    
    setIsLoading(false);
    refetch();
  }

  return (
    <FormWrapper>
      <AntdForm
        name="basic"
        onFinish={handleSubmit}
        style={{ padding: '30px' }}
        layout="vertical"
        initialValues={initialFields}
        ref={formRef}
      >
        <Row gutter={40}>
          <Col span={12}>
            <CardWrapper type="inner" title="Informações Gerais">
              <SelectProprietario
                name="idProprietario"
                defaultValue={initialFields.idProprietario}
                title="Proprietário"
                disabled={formType === 'view' ? true : false}
                //postUrl={getSubDomain() + "pessoas"}
                fetchUrl="proprietarios"
              />

              <SelectOperadora
                name="idOperadora"
defaultValue={initialFields.idOperadora}
                title="Operadora"
                disabled={formType === 'view' ? true : false}
                //postUrl={getSubDomain() + "pessoas"}
                fetchUrl="operadoras"
              />

              <AntdForm.Item label="Número" name="numero" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <Input placeholder="" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>

              <AntdForm.Item label="Contrato" name="contrato">
                <UploadComponent
                  maxCount={1}
                  disabled={formType === 'view' ? true : false}
                  fileList={fileListcontrato}
                  setFileList={setFileListcontrato}
                />
              </AntdForm.Item>
            </CardWrapper>
          </Col>

          <Col span={12}>
            <CardWrapper type="inner" title="Dados Diversos">
              <AntdForm.Item
                label="Data Início do Contrato"
                name="contratoInicio"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder="Selecione uma Data"
                  disabled={formType === 'view' ? true : false}
                />
              </AntdForm.Item>

              <AntdForm.Item
                label="Data Fim do Contrato"
                name="contratoFim"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder="Selecione uma Data"
                  disabled={formType === 'view' ? true : false}
                />
              </AntdForm.Item>
            </CardWrapper>
          </Col>
        </Row>
        {formType == 'view' ? (
          <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
            <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
              Fechar
            </Button>
          </footer>
        ) : (
          <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
            <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
              Voltar
            </Button>

            <Button type="primary" htmlType="submit" loading={isLoading}>
              Salvar
            </Button>
          </footer>
        )}
      </AntdForm>
    </FormWrapper>
  );
}
