import React from 'react';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';

import { IItemProps } from '../../types';
import { TableButtonDelete } from './TableButtonDelete';
import { TableButtonEdit } from './TableButtonEdit';
import { TableButtonRestore } from './TableButtonRestore';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

interface ITableColumnControllerProps {
  handleDeleteFunction: (contactId: string) => Promise<void>;
  handleRestoreFunction: (contactId: string) => Promise<void>;
  refetch: () => void;
  dataList?: IItemProps[];
}

export function TableColumnController({
  handleDeleteFunction,
  handleRestoreFunction,
  refetch,
  dataList,
}: ITableColumnControllerProps) {
  const history = useHistory();

  const columns: ColumnsType<IItemProps> = [
    {
      title: 'Estudante',
      dataIndex: '',
      render: (_, data) => (
        <p style={{ margin: 'auto 0' }}>{data?.estudante?.pessoa?.nome ? data?.estudante?.pessoa?.nome : '---'}</p>
      ),
    },

    {
      title: 'Presença',
      dataIndex: 'status',
      align: 'left',
      render: (_, row: IItemProps) => (
        <span
          style={{
            color: row.status === 1 ? '#20C9A5' : '#faad14',
            background: row.status === 1 ? '#E2F4F0' : '#fffbe6',
            border: `0px solid ${row.status === 1 ? '#b7eb8f' : '#ffe58f'}`,
            padding: '3px 10px',
            borderRadius: 40,
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            boxSizing: 'border-box',
            fontVariant: 'tabular-nums',
            listStyle: 'none',
            fontFeatureSettings: 'tnum',
            display: 'inline-block',
            height: 'auto',
            fontSize: '12px',
            lineHeight: '20px',
            whiteSpace: 'nowrap',
            transition: 'all 0.3s',
          }}
        >
          {row.status === 1 ? 'Sim' : 'Não'}
        </span>
      ),
    },
  ];

  return { columns, data: dataList };
}
