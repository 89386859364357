import { ColumnsType } from 'antd/lib/table';

import { IRotaProps } from '../../types';
import { useHistory } from 'react-router-dom';

interface ITableColumnControllerProps {
  handleDeleteFunction: (rotaId: string) => Promise<void>;
  handleRestoreFunction: (rotaId: string) => Promise<void>;
  refetch: () => void;
  dataList?: IRotaProps[];
}

export function TableColumnController({ dataList }: ITableColumnControllerProps) {
  const history = useHistory();

  const columns: ColumnsType<IRotaProps> = [
    {
      title: 'Nome',
      dataIndex: 'sigla',
      sorter: true,
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      sorter: true,
      render: () => 'Descrição não disponível',
    },
  ];

  return { columns, data: dataList };
}
