import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { getSubDomain } from '../../../hooks/getSubDomain';
import { Button, Input, Select, Form as AntdForm, Image } from 'antd';
import { CardWrapper, FormWrapper } from '../styles';
import { api } from '../../../services/api';
import { IItemProps } from '../types';
import { UploadFile } from 'antd/lib/upload/interface';
import toast from 'react-hot-toast';
import FeatherIcon from 'feather-icons-react';
import TextArea from 'antd/lib/input/TextArea';

interface IFormComponent {
  formType: 'put' | 'post' | 'view';
  initialFields: IItemProps;
  refetch: () => void;
  setModalIsOpen: (event: boolean) => void;
}

export function Form({ formType, setModalIsOpen, refetch, initialFields }: IFormComponent) {
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([] as UploadFile[]);

  const formRef = useRef<any>(null);

  function clearFieldsAndCloseModal() {
    setModalIsOpen(false);
  }

  async function handleSubmit(formData: any) {
    setIsLoading(true);
    console.log(formData);
    try {
      await api.put(getSubDomain() + `ocorrencias/${initialFields.id}`, {
        idPessoa: initialFields.idPessoa,
        nome: initialFields.nome,
        ocorrencia: initialFields.ocorrencia,
        data: initialFields.data,
        localizacao: initialFields.localizacao,
        imagem: initialFields.imagem,
        codigo: initialFields.codigo,
        resposta: formData.resposta,
      });
      toast.success('Salvo com sucesso');
      setModalIsOpen(false);
      formRef.current.resetFields();
    } catch (err) {
      console.log(err);
      toast.error('Erro ao Salvar');
    }

    setIsLoading(false);
    refetch();
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBTrszleNQu-jzMW6W_cY7WEWlgxf6U9ZM',
  });

  const containerStyle = {
    width: '500px',
    height: '400px',
  };

  const center = {
    lat: Number(initialFields.localizacao.split(',')[0]),
    lng: Number(initialFields.localizacao.split(',')[1]),
  };

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {}, []);

  const isRespondido = initialFields.status.toString() === '2';
  const palavra = isRespondido ? 'Respondido' : 'Não Respondido';

  return (
    <FormWrapper>
      <AntdForm
        name="Ocorrências"
        onFinish={handleSubmit}
        style={{ padding: '30px', width: '570px' }}
        layout="vertical"
        ref={formRef}
      >
        <CardWrapper type="inner" title="Informações Gerais">
          <p>
            <strong>Nome: </strong>
            <span>{initialFields.nome}</span>
          </p>

          <p>
            <strong>Ocorrência: </strong>
            <span>{initialFields.ocorrencia}</span>
          </p>

          <p>
            <strong>Nome pessoa: </strong>
            <span>{initialFields.pessoa.nome}</span>
          </p>

          <p>
            <strong>Data e horário: </strong>
            <span>{initialFields.createdAt}</span>
          </p>

          <p>
            <strong>Status: </strong>
            <span>{palavra}</span>
          </p>

          <p>
            <strong>Código: </strong>
            <span>{initialFields.codigo}</span>
          </p>

          {isRespondido && (
            <p>
              <strong>Resposta: </strong>
              <span>{initialFields.resposta}</span>
            </p>
          )}
        </CardWrapper>

        {!isRespondido && (
          <CardWrapper type="inner" title="Resposta" style={{ marginTop: '30px' }}>
            <AntdForm.Item label="Resposta" name="resposta" rules={[{ required: true, message: 'Campo Obrigatório!' }]}>
              <TextArea
                style={{ resize: 'none' }}
                //disabled={isRespondido}
                value={isRespondido ? initialFields.resposta : ''}
                rows={8}
              />
            </AntdForm.Item>
          </CardWrapper>
        )}
        <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
          {!isRespondido && (
            <Button type="default" size="large" htmlType="button" onClick={clearFieldsAndCloseModal}>
              Cancelar
            </Button>
          )}
          {!isRespondido && (
            <Button type="primary" size="large" htmlType="submit" loading={isLoading}>
              <FeatherIcon icon="check" size={14} style={{ marginRight: 10 }} />
              Enviar
            </Button>
          )}
        </footer>
      </AntdForm>

      <div style={{ padding: '30px 30px 0 0' }}>
        {isLoaded && (
          <GoogleMap mapContainerStyle={containerStyle} center={center} onLoad={onLoad} onUnmount={onUnmount}>
            <Marker position={center} title="Aconteceu aqui." />
          </GoogleMap>
        )}

        <Button
          style={{ marginTop: '2rem' }}
          onClick={() =>
            window.open(`https://app.gestaoderotas.com.br/imagens/spat/ocorrencias/${initialFields.imagem}`, '_blank')
          }
        >
          Ver Foto
        </Button>
      </div>
    </FormWrapper>
  );
}
