import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Select as AntdSelect, Form as AntdForm, Input, Button, Modal } from 'antd';
import { api } from '../../services/api';
import toast from 'react-hot-toast';
import { ISelectPessoaComponent, IFetchProps, IPessoaFetchProps } from './types';
import { useFetch } from '../../hooks/useFetch';
import { CardWrapper, FormWrapper } from './styles';
import { SelectSegmento } from '.';
import { SelectSexo } from './SelectSexo';
import { SelectEstadoCivil } from './SelectEstadoCivil';
import { SelectNacionalidade } from './SelectNacionalidade';

export function SelectPessoa({
  title,
  postUrl = '',
  fetchUrl,
  disabled,
  name,
  onChange,
  defaultValue = '',
}: ISelectPessoaComponent) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [dataInputModal, setDataInputModal] = useState('');

  const [dataNome, setDataNome] = useState('');
  const [dataCpf, setDataCpf] = useState('');
  const [dataRg, setDataRg] = useState('');
  const [dataEndereco, setDataEndereco] = useState('');
  const [dataNumero, setDataNumero] = useState('');
  const [dataComplemento, setDataComplemento] = useState('');
  const [dataBairro, setDataBairro] = useState('');
  const [dataCidade, setDataCidade] = useState('');
  const [dataUf, setDataUf] = useState('');
  const [dataTel1, setDataTel1] = useState('');
  const [dataTel2, setDataTel2] = useState('');
  const [dataCep, setDataCep] = useState('');
  const [dataIdSexo, setDataIdSexo] = useState('');
  const [dataIdNacionalidade, setDataIdNacionalidade] = useState('');
  const [dataIdEstadoCivil, setDataIdEstadoCivil] = useState('');

  const [selectValue, setSelectValue] = useState({ value: '', label: '' });

  const { dataFetch, refetch, search, setSearch, setItensPerPage, isFetching } = useFetch<IPessoaFetchProps[]>({
    baseUrl: fetchUrl,
    isArray: true,
  });
  const formPessoa = useRef<any>(null);

  const { dataFetch: dataFetch2 } = useFetch<IPessoaFetchProps>({
    baseUrl: fetchUrl + `/${defaultValue}`,
    isLinkProps: false,
    isArray: false,
  });

  async function handleNewSelect() {
    const { data } = await api.post(postUrl, {
      name: dataInputModal,
      nome: dataNome,
      cpf: dataCpf,
      rg: dataRg,
      endereco: dataEndereco,
      numero: dataNumero,
      cep: dataCep,
      bairro: dataBairro,
      complemento: dataComplemento,
      cidade: dataCidade,
      uf: dataUf,
      tel1: dataTel1,
      tel2: dataTel2,
      idSexo: dataIdSexo,
      idEstadoCivil: dataIdEstadoCivil,
      idNacionalidade: dataIdNacionalidade,
    });

    setSelectValue({
      value: String(data.id),
      label: data.nome,
    });

    setModalIsOpen(false);
    refetch();
  }

  function handleSelected(event: string) {
    setSelectValue({ label: event, value: event });
    if (onChange) onChange(event);
  }

  let timer: NodeJS.Timeout;

  function debounce(event: string) {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setSearch(event);
    }, 1000);
  }

  useEffect(() => {
    setItensPerPage(1000);
  }, []);

  return (
    <>
      <div style={{ display: 'flex', gap: '1rem' }}>
        {!search && isFetching ? (
          <AntdForm.Item
            label={title}
            name="banana"
            style={{ width: '100%' }}
            rules={[{ required: true, message: `Campo obrigatório` }]}
          >
            <p style={{ height: '2.35rem', padding: '5px 8px', borderRadius: '4px', border: '1px solid #CCC' }}>
              Carregando...
            </p>
          </AntdForm.Item>
        ) : (
          <AntdForm.Item
            label={title}
            name={name}
            style={{ width: '100%' }}
            rules={[{ required: true, message: `Campo obrigatório` }]}
          >
            <AntdSelect
              showSearch
              disabled={disabled}
              loading={isFetching}
              filterOption={false}
              placeholder={title}
              onSearch={e => debounce(e)}
              value={selectValue.value !== '' && String(selectValue.value)}
              onChange={value => handleSelected(String(value))}
            >
              {!dataFetch?.find(item => String(item.id) === defaultValue) && (
                <>
                  <AntdSelect.Option value={String(dataFetch2?.id)}>{dataFetch2?.nome}</AntdSelect.Option>
                </>
              )}

              {dataFetch?.map(segment => (
                <AntdSelect.Option value={String(segment.id)}>{segment.nome}</AntdSelect.Option>
              ))}
            </AntdSelect>
          </AntdForm.Item>
        )}

        {postUrl && (
          <Button onClick={() => setModalIsOpen(true)} style={{ marginTop: '2.20rem', height: '2.3rem' }}>
            +
          </Button>
        )}
      </div>

      <Modal
        title="Adicionar Pessoa"
        visible={!postUrl ? false : modalIsOpen}
        onCancel={() => setModalIsOpen(false)}
        footer={null}
        maskClosable={false}
        width={1000}
      >
        <FormWrapper>
          <AntdForm name="basic" style={{ padding: '0px' }} layout="vertical" ref={formPessoa}>
            <Row gutter={40}>
              <Col span={12}>
                <CardWrapper type="inner" title="Informações Gerais">
                  <AntdForm.Item label="Nome" name="nome" rules={[{ required: true, message: 'Nome obrigatório' }]}>
                    <Input placeholder="Nome Completo" value={dataNome} onChange={e => setDataNome(e.target.value)} />
                  </AntdForm.Item>

                  <SelectSexo
                    name="idSexo"
                    title="Sexo"
                    disabled={false}
                    fetchUrl="sexos"
                    onChange={value => setDataIdSexo(value)}
                  />

                  <SelectEstadoCivil
                    name="idEstadoCivil"
                    title="Estado Civil"
                    fetchUrl="estadocivils"
                    disabled={false}
                    onChange={value => setDataIdEstadoCivil(value)}
                  />

                  <SelectNacionalidade
                    name="idNacionalidade"
                    title="Nacionalidade"
                    fetchUrl="nacionalidades"
                    disabled={false}
                    onChange={value => setDataIdNacionalidade(value)}
                  />

                  <AntdForm.Item
                    label="CPF"
                    name="cpf"
                    rules={[
                      { required: true, message: 'Campo Obrigatório!' },
                      { max: 11, message: 'O campo deve conter no máximo 11 caracteres.' },
                    ]}
                  >
                    <Input placeholder="CPF" value={dataCpf} onChange={e => setDataCpf(e.target.value)} />
                  </AntdForm.Item>

                  <AntdForm.Item
                    label="RG"
                    name="rg"
                    rules={[
                      { required: true, message: 'Campo Obrigatório!' },
                      { max: 20, message: 'O campo deve conter no máximo 20 caracteres.' },
                    ]}
                  >
                    <Input placeholder="RG" value={dataRg} onChange={e => setDataRg(e.target.value)} />
                  </AntdForm.Item>
                </CardWrapper>
              </Col>

              <Col span={12}>
                <CardWrapper type="inner" title="Dados de Endereço">
                  <AntdForm.Item
                    label="Rua"
                    name="endereco"
                    rules={[
                      { required: true, message: 'Campo Obrigatório!' },
                      { max: 120, message: 'O campo deve conter no máximo 120 caracteres.' },
                    ]}
                  >
                    <Input
                      placeholder="Endereço"
                      value={dataEndereco}
                      onChange={e => setDataEndereco(e.target.value)}
                    />
                  </AntdForm.Item>
                  <Row gutter={15}>
                    <Col span={8}>
                      <AntdForm.Item
                        label="Número"
                        name="numero"
                        rules={[
                          { required: true, message: 'Campo Obrigatório!' },
                          { max: 120, message: 'O campo deve conter no máximo 120 caracteres.' },
                        ]}
                      >
                        <Input placeholder="Número" value={dataNumero} onChange={e => setDataNumero(e.target.value)} />
                      </AntdForm.Item>
                    </Col>
                    <Col span={16}>
                      <AntdForm.Item
                        label="Complemento"
                        name="complemento"
                        rules={[{ max: 120, message: 'O campo deve conter no máximo 120 caracteres.' }]}
                      >
                        <Input
                          placeholder="Complemento"
                          value={dataComplemento}
                          onChange={e => setDataComplemento(e.target.value)}
                        />
                      </AntdForm.Item>
                    </Col>
                  </Row>

                  <Row gutter={15}>
                    <Col span={12}>
                      <AntdForm.Item
                        label="CEP"
                        name="cep"
                        rules={[
                          { required: true, message: 'Campo Obrigatório!' },
                          { max: 8, message: 'O campo deve conter no máximo 8 caracteres.' },
                        ]}
                      >
                        <Input placeholder="CEP" value={dataCep} onChange={e => setDataCep(e.target.value)} />
                      </AntdForm.Item>
                    </Col>
                    <Col span={12}>
                      <AntdForm.Item
                        label="Bairro"
                        name="bairro"
                        rules={[
                          { required: true, message: 'Campo Obrigatório!' },
                          { max: 45, message: 'O campo deve conter no máximo 45 caracteres.' },
                        ]}
                      >
                        <Input placeholder="Bairro" value={dataBairro} onChange={e => setDataBairro(e.target.value)} />
                      </AntdForm.Item>
                    </Col>
                  </Row>
                  <Row gutter={15}>
                    <Col span={16}>
                      <AntdForm.Item
                        label="Cidade"
                        name="cidade"
                        rules={[
                          { required: true, message: 'Campo Obrigatório!' },
                          { max: 45, message: 'O campo deve conter no máximo 45 caracteres.' },
                        ]}
                      >
                        <Input placeholder="Cidade" value={dataCidade} onChange={e => setDataCidade(e.target.value)} />
                      </AntdForm.Item>
                    </Col>
                    <Col span={8}>
                      <AntdForm.Item
                        label="UF"
                        name="uf"
                        rules={[
                          { required: true, message: 'Campo Obrigatório!' },
                          { max: 2, message: 'O campo deve conter no máximo 2 caracteres.' },
                        ]}
                      >
                        <Input placeholder="Estado" value={dataUf} onChange={e => setDataUf(e.target.value)} />
                      </AntdForm.Item>
                    </Col>
                  </Row>
                  <AntdForm.Item
                    label="Telefone 1"
                    name="tel1"
                    rules={[
                      { required: true, message: 'Campo Obrigatório!' },
                      { max: 14, message: 'O campo deve conter no máximo 14 caracteres.' },
                    ]}
                  >
                    <Input placeholder="Telefone" value={dataTel1} onChange={e => setDataTel1(e.target.value)} />
                  </AntdForm.Item>

                  <AntdForm.Item
                    label="Telefone 2"
                    name="tel2"
                    rules={[{ max: 14, message: 'O campo deve conter no máximo 14 caracteres.' }]}
                  >
                    <Input placeholder="Telefone" value={dataTel2} onChange={e => setDataTel2(e.target.value)} />
                  </AntdForm.Item>
                </CardWrapper>
              </Col>
            </Row>
            <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
              <Button type="primary" htmlType="submit" onClick={handleNewSelect}>
                Adicionar
              </Button>
            </footer>
          </AntdForm>
        </FormWrapper>
      </Modal>
    </>
  );
}
