import { ColumnsType } from 'antd/lib/table';
import { IVeiculoProps } from '../../../veiculos/types';

interface ITableColumnControllerProps {
  dataList?: IVeiculoProps[];
}

export function TableColumnController({ dataList }: ITableColumnControllerProps) {
  const columns: ColumnsType<any> = [
    {
      title: 'Código da demanda',
      dataIndex: 'siglaa',
      sorter: true,
      render: (_, data) => data.codigo,
    },
    {
      title: 'Data',
      dataIndex: 'siglaa',
      sorter: true,
      render: (_, data) => new Date(data.data).toLocaleDateString('pt-br'),
    },
    {
      title: 'Hora',
      dataIndex: 'siglaa',
      sorter: true,
      render: (_, data) => new Date(data.data).toLocaleTimeString('pt-br'),
    },
    {
      title: 'Municipio',
      dataIndex: 'siglaa',
      sorter: true,
      render: (_, data) => data?.idRota?.idCidade?.nome ?? 'Não definido',
    },
    {
      title: 'Nome do usuário',
      dataIndex: 'siglaa',
      sorter: true,
      render: (_, data) => data.pessoa.nome,
    },
    {
      title: 'Cod rota',
      dataIndex: 'siglaa',
      sorter: true,
      render: (_, data) => data.idRota.sigla,
    },
    {
      title: 'Tipo de demanda',
      dataIndex: 'siglaa',
      sorter: true,
      render: (_, data) => data.ocorrencia,
    },
    {
      title: 'Status',
      dataIndex: 'siglaa',
      sorter: true,
      render: (_, data) => (data.status === 1 ? 'Ativo' : 'Desativo'),
    },
  ];

  return { columns, data: dataList };
}
