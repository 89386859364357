import React from 'react';
import { Button, Upload, UploadProps } from 'antd';
import Cookies from 'js-cookie';
import { UploadFile } from 'antd/lib/upload/interface';
import { getSubDomain } from '../hooks/getSubDomain';

type IUploadComponent = {
  maxCount?: number;
  fileList: UploadFile[];
  disabled: boolean;
  setFileList: (event: UploadFile[]) => void;
};

export function UploadComponent({ maxCount = 5, fileList, disabled, setFileList }: IUploadComponent) {
  const token = Cookies.get('whats-front-token');

  if (disabled == false) {
    const props: UploadProps = {
      name: 'document',
      action: 'https://apiws.gestaoderotas.com.br/files' + getSubDomain() + 'files',
      headers: {
        authorization: `Bearer ${token}`,
      },

      onChange(info) {
        if (info.fileList) {
          setFileList(info.fileList);
        }
      },
    };
    return (
      <Upload {...props} maxCount={maxCount} defaultFileList={[...fileList]}>
        <Button>Clique para Upload</Button>
      </Upload>
    );
  } else {
    const props: UploadProps = {
      name: 'document',
      action: 'https://apiws.gestaoderotas.com.br/files' + getSubDomain() + 'files',
      headers: {
        authorization: `Bearer ${token}`,
      },
      showUploadList: {
        showDownloadIcon: true,
        downloadIcon: 'Download',
        showRemoveIcon: false,
        // removeIcon: <StarOutlined onClick={e => console.log(e, 'custom removeIcon event')} />,
      },

      onChange(info) {
        if (info.fileList) {
          setFileList(info.fileList);
        }
      },
    };
    return (
      /*  <Button 
    className="btn-icon" 
    type="info" 
    onClick={() => history.push("rotastrajetos/?idRota="+item.id)}
    to="#" 
    shape="circle"
    >
    <FeatherIcon icon="list" size={16} />
  </Button> */

      <Upload {...props} maxCount={maxCount} defaultFileList={[...fileList]}></Upload>
    );
  }
}
