import React from "react";
import moment from "moment";
import { ColumnsType } from "antd/lib/table";

import { IItemProps } from "../../types";
import { TableButtonDelete } from "./TableButtonDelete";
import { TableButtonEdit } from "./TableButtonEdit";
import { TableButtonRestore } from "./TableButtonRestore";

interface ITableColumnControllerProps {
  handleDeleteFunction: (contactId: string) => Promise<void>;
  handleRestoreFunction: (contactId: string) => Promise<void>;
  
  refetch: () => void;
  dataList?: IItemProps[];
}

export function TableColumnController({ 
  handleDeleteFunction, 
  handleRestoreFunction,  
 
  refetch, 
  dataList 
}: ITableColumnControllerProps) {
  
  const columns: ColumnsType<IItemProps> = [
    {
      title: "Nome",
      dataIndex: "nome",
      sorter: true,
    },

    {
      title: "UF",
      dataIndex: "uf",
      sorter: true,
    },

    {
      title: "Sigla",
      dataIndex: "sigla",
      sorter: true,
    },

    

    {
      title: 'Status',
      dataIndex: 'status',
      render: (_, row: IItemProps) => (
          <span style={{
            color: row.status === 1 ? '#20C9A5' : '#faad14',
            background: row.status === 1 ? '#E2F4F0' : '#fffbe6',
            border: `0px solid ${row.status === 1 ? '#b7eb8f' : '#ffe58f'}`,
            padding: '3px 10px',
            borderRadius: 40,
            justifyContent: 'center',
            alignItems:'center',
            textAlign:'center',
            boxSizing: 'border-box',
            fontVariant: 'tabular-nums',
            listStyle: 'none',
            fontFeatureSettings: 'tnum',
            display: 'inline-block',
            height: 'auto',
            fontSize: '12px',
            lineHeight: '20px',
            whiteSpace: 'nowrap',
            transition: 'all 0.3s',
            
          }}>
            {row.status === 1 ? 'Ativo' : 'Inativo'}
          </span>
        ),
      
    },

    {
      title: "Ações",
      dataIndex: "actions",
      render:  (_, item) => {
        console.log(item)
        return (
          <div className="table-actions">
             
            {item.status === 0  ? (
                <TableButtonRestore
                handleRestoreFunction={handleRestoreFunction} 
                contactId={String(item.id)} 
              />

):(
  <>
            <TableButtonEdit 
              contactId={String(item.id)} 
              refetch={refetch}
              data={item}
            />
  
            <TableButtonDelete 
              handleDeleteFunction={handleDeleteFunction} 
              contactId={String(item.id)} 
            />
   </>
  )}
          </div>
        )
      }
    },
  ];

  

  const data: any[] = [];

  dataList?.forEach((item) => {
    data.push({
      key: item.id,
      id: item.id,
      nome: item.nome,
      sigla: item.sigla,
      uf: item.uf,
      status: item.status,
      createdAt: moment(item.createdAt).format("DD/MM/YYYY"),
    })
  })
  
  return { columns, data }
}
