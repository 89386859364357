import React from 'react';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';

import { IGestorProps } from '../../types';
import { TableButtonDelete } from './TableButtonDelete';
import { TableButtonEdit } from './TableButtonEdit';
import { TableButtonView } from './TableButtonView';
import { TableButtonRestore } from './TableButtonRestore';

interface ITableColumnControllerProps {
  handleDeleteFunction: (gestorId: string) => Promise<void>;
  handleRestoreFunction: (gestorId: string) => Promise<void>;
  refetch: () => void;
  dataList?: IGestorProps[];
}

export function TableColumnController({
  handleDeleteFunction,
  handleRestoreFunction,
  refetch,
  dataList,
}: ITableColumnControllerProps) {
  const columns: ColumnsType<IGestorProps> = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      sorter: true,
    },

    {
      title: 'Ações',
      dataIndex: 'actions',
      render: (_, item) => {
        return (
          <div className="table-actions">
            {item.status === 0 ? (
              <TableButtonRestore handleRestoreFunction={handleRestoreFunction} contactId={String(item.id)} />
            ) : (
              <>
                <TableButtonView gestorId={String(item.id)} refetch={refetch} data={item} />
                <TableButtonEdit gestorId={String(item.id)} refetch={refetch} data={item} />

                <TableButtonDelete handleDeleteFunction={handleDeleteFunction} gestorId={String(item.id)} />
              </>
            )}
          </div>
        );
      },
    },
  ];

  const data: any[] = [];

  dataList?.forEach(item => {
    data.push({
      key: item.id,
      id: item.id,
      createdAt: moment(item.createdAt).format('DD/MM/YYYY'),
      idGestor: String(item.idGestor),
      cidade: item.cidade,
      email: item.email,
      site: item.site,
      nome: item.nome,
      endereco: item.endereco,
      numero: item.numero,
      bairro: item.bairro,
      complemento: item.complemento,
      cep: item.cep,
      sigla: item.sigla,
      tel1: item.tel1,
      tel2: item.tel2,
      logomarca: item.logomarca,
      cnpj: item.cnpj,
      ie: item.ie,
      status: item.status,
    });
  });

  return { columns, data };
}
