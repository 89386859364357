import { ColumnsType } from 'antd/lib/table';
import { IVeiculoProps } from '../../../veiculos/types';

interface ITableColumnControllerProps {
  dataList?: IVeiculoProps[];
}

export function TableColumnController({ dataList }: ITableColumnControllerProps) {

  const columns: ColumnsType<any> = [
    {
      title: 'Modelo',
      dataIndex: 'siglaw',
      sorter: true,
      render: (_, data) => data.idVeiculo.modelo,
    },
    {
      title: 'Placa',
      dataIndex: 'siglaa',
      sorter: true,
      render: (_, data) => data.idVeiculo.placa,
    },
    {
      title: 'Rota',
      dataIndex: 'siglaa',
      sorter: true,
      render: (_, data) => data.sigla,
    },
    {
      title: 'Dias da semana rodados',
      dataIndex: 'siglaa',
      sorter: true,
      render: (_, data) => data.diarios.length + ' dias rodados',
    },
  ];

  return { columns, data: dataList };
}
