import React, { useEffect, useRef, useState } from 'react';

import { Form as AntdForm, Button, Input, Select } from 'antd';
import moment from 'moment';
import { SelectRota } from '../../../components/Select/SelectRota';
import { SelectRotaTrajeto } from '../../../components/Select/SelectRotaTrajeto';
import { getSubDomain } from '../../../hooks/getSubDomain';
import { useAuth } from '../../../hooks/useAuth';
import { api } from '../../../services/api';
import { CardWrapper, FormWrapper } from '../styles';

interface IFormInitialFields {
  idPessoa: string;
  idRota: string;
  idEscola: string;
  idRotaTrajeto: string;
  deficiente: string;
  idDeficiencia: string;
  atividadeInicio: moment.Moment;
  atividadeFim: moment.Moment;
}

interface IFormComponent {
  formType: 'put' | 'post' | 'view';
  initialFields?: any;
  refetch: () => void;
  estudanteId?: string;
  setModalIsOpen: (event: boolean) => void;
}

export function Form({
  formType,
  setModalIsOpen,
  refetch,
  estudanteId,
  initialFields = {
    idEscola: '',
    deficiente: '',
    idPessoa: '',
    idRotaTrajeto: '',
    idDeficiencia: '',
    atividadeInicio: moment(),
    atividadeFim: moment(),
    idRota: '',
  },
}: IFormComponent) {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();

  const idEscola = user?.idEscola;

  const [idRota, setIdRota] = useState(initialFields.idRota);

  const formRef = useRef<any>(null);

  function clearFieldsAndCloseModal() {
    setModalIsOpen(false);
    formRef.current.resetFields();
  }

  function handleChange() {
    if (formRef?.current) formRef.current.setFieldsValue({ idRotaTrajeto: null });
  }

  async function handleSubmit(data: any) {
    setIsLoading(true);

    try {
      if (formType === 'post') {
        const response = await api.post(getSubDomain() + 'pessoas', {
          nome: data.nome,
          endereco: data.endereco,
          tel1: data.tel1,
        });

        const idPessoa = response.data.id;

        await api.post(getSubDomain() + 'estudantes', {
          idPessoa,
          idEscola,
          idRota: data.idRota,
          idRotaTrajeto: data.idRotaTrajeto,
          deficiente: data.deficiente,
          atividadeInicio: '01/01/2000',
          atividadeFim: '31/12/2090',
        });
      }

      if (formType === 'put') {
        const response = await api.put(getSubDomain() + 'pessoas/' + initialFields.idPessoa, {
          nome: data.nome,
          endereco: data.endereco,
          tel1: data.tel1,
        });

        const idPessoa = response.data.id;

        await api.put(getSubDomain() + 'estudantes/' + estudanteId, {
          idPessoa,
          idEscola,
          idRota: data.idRota,
          idRotaTrajeto: data.idRotaTrajeto,
          deficiente: data.deficiente,
          atividadeInicio: '01/01/2000',
          atividadeFim: '31/12/2090',
        });
      }

      clearFieldsAndCloseModal();
    } catch (err) {}

    setIsLoading(false);
    refetch();
  }

  return (
    <FormWrapper>
      <AntdForm
        name="basic"
        onFinish={handleSubmit}
        style={{ padding: '30px' }}
        layout="vertical"
        initialValues={initialFields}
        ref={formRef}
      >
        <CardWrapper type="inner" title="Informações Gerais">
          <AntdForm.Item
            label="Nome do estudante"
            name="nome"
            rules={[{ required: true, message: 'Campo Obrigatório!' }]}
          >
            <Input disabled={formType === 'view'} placeholder="Escreva aqui..." />
          </AntdForm.Item>
          <AntdForm.Item label="Endereço" name="endereco" rules={[{ required: true, message: 'Campo Obrigatório!' }]}>
            <Input disabled={formType === 'view'} placeholder="Escreva aqui..." />
          </AntdForm.Item>
          <AntdForm.Item
            label="Telefone contato"
            name="tel1"
            rules={[{ required: true, message: 'Campo Obrigatório!' }]}
          >
            <Input disabled={formType === 'view'} placeholder="Escreva aqui..." />
          </AntdForm.Item>
          <SelectRota
            name="idRota"
            onChange={e => {
              handleChange();
              setIdRota(e);
            }}
            title="Rota"
            disabled={formType === 'view' ? true : false}
            fetchUrl="rotas"
          />

          <SelectRotaTrajeto
            name="idRotaTrajeto"
            disabled={formType === 'view' ? true : false}
            title="Trajeto"
            idRota={idRota}
            defaultValue={initialFields.idRotaTrajeto}
            fetchUrl="rotatrajetos"
          />

          <AntdForm.Item
            label="Deficiente?"
            name="deficiente"
            rules={[{ required: true, message: 'Campo Obrigatório!' }]}
          >
            <Select placeholder="Selecione uma Opção" disabled={formType === 'view' ? true : false}>
              <Select.Option value={1}>Sim</Select.Option>
              <Select.Option value={0}>Não</Select.Option>
            </Select>
          </AntdForm.Item>
        </CardWrapper>
        {formType === 'view' ? (
          <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
            <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
              Fechar
            </Button>
          </footer>
        ) : (
          <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
            <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
              Voltar
            </Button>

            <Button type="primary" htmlType="submit" loading={isLoading}>
              Salvar
            </Button>
          </footer>
        )}
      </AntdForm>
    </FormWrapper>
  );
}
