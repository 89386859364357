import React from 'react';
import { Row } from 'antd';

import { ClearFilterButton } from './components/ClearFilterButton';
import { FilterButton } from './components/FilterButton';
import { SearchInput } from './components/SearchInput';
import { TableComponent } from './components/table';
import { ButtonAdd } from './components/table/TableButtonAdd';
import { StatusRadioGroup } from './components/StatusRadioGroup';

import { ThemeLayout } from '../../layout/themeLayout';
import { Main, TableWrapper } from '../../container/styled';
import { TopToolBox } from '../../container/ecommerce/Style';
import { ExportButtonPageHeader } from '../../components/buttons/export-button/export-button';

import { PageHeader } from '../../components/page-headers/page-headers';
import { Cards } from '../../components/cards/frame/cards-frame';

import { useFetch } from '../../hooks/useFetch';
import { IFetchPropsVeiculos } from './types';
import Alert from '../../components/alerts/alerts';

export function Veiculos() {
  const {
    isFetching,
    dataFetch,
    setSearch,
    filters,
    setStatus,
    handleAddFilters,
    clearFilters,
    refetch,
    currentPage,
    itensPerPage,
    setCurrentPage,
    setColumnOrdenation,
    setItensPerPage,
    totalItens,
    cityName,
    handleChangeCityFilter,
  } = useFetch<IFetchPropsVeiculos>({ baseUrl: 'veiculos' });

  return (
    <ThemeLayout>
      {cityName && (
        <Alert
          type="warning"
          message={`O filtro aplicado resulta em uma lista de registros relacionados às ROTAS da cidade de ${cityName}`}
        />
      )}

      <PageHeader
        ghost
        title="Veículos"
        buttons={[
          <div key="1" className="page-header-actions">
            {filters.length !== 0 && <ClearFilterButton clearFilter={clearFilters} />}
            <FilterButton
              handleChangeCityFilter={handleChangeCityFilter}
              filters={filters}
              handleAddFilters={handleAddFilters}
              clearFilters={clearFilters}
            />
          </div>,
        ]}
      />

      <Main>
        <Cards headless>
          <TopToolBox>
            <Row gutter={15} className="justify-content-center">
              <SearchInput handleSearch={setSearch} />
              <StatusRadioGroup setStatus={setStatus} />
              <ButtonAdd refetch={refetch} />
            </Row>
          </TopToolBox>

          <TableWrapper className="table-order table-responsive">
            <TableComponent
              setCurrentPage={setCurrentPage}
              setColumnOrdenation={setColumnOrdenation}
              currentPage={currentPage}
              refetch={refetch}
              isLoading={isFetching}
              dataFetch={dataFetch}
              totalItens={totalItens}
              itensPerPage={itensPerPage}
              setItensPerPage={setItensPerPage}
            />
          </TableWrapper>
        </Cards>
      </Main>
    </ThemeLayout>
  );
}
