import { ColumnsType } from 'antd/lib/table';
import { IVeiculoProps } from '../../../veiculos/types';

interface ITableColumnControllerProps {
  dataList?: IVeiculoProps[];
}

export function TableColumnController({ dataList }: ITableColumnControllerProps) {
  const columns: ColumnsType<any> = [
    {
      title: 'Nome do motorista',
      dataIndex: 'siglaa',
      sorter: true,
      render: (_, data) => data.idCondutor.pessoa.nome,
    },
    {
      title: 'Telefone contato',
      dataIndex: 'siglaw',
      sorter: true,
      render: (_, data) =>  data.idCondutor.pessoa.tel1,
    },
    {
      title: 'Modelo',
      dataIndex: 'siglaa',
      sorter: true,
      render: (_, data) => data.idVeiculo.modelo,
    },
    {
      title: 'Placa',
      dataIndex: 'siglaa',
      sorter: true,
      render: (_, data) => data.idVeiculo.placa,
    },
  ];

  return { columns, data: dataList };
}
