import Styled from 'styled-components';
import { Card } from 'antd';

export const CardWrapper = Styled(Card)`
  header {
    display: flex;
   
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  .left-header {
    display: flex;
    align-items: center;
    background: red;

    h4 {
      margin: 0;
      margin-right: 16px;
    }

    button {
      margin-right: 16px;
    }
  }

  .actions {
    button {
      margin-left: 14px;
    }
  }
`;

export const FiltersWrapper = Styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .ant-picker {
    width: 100%;
  }

 .ant-picker-input input {
    padding: 10px;
  }
    .ant-input[disabled] {
    color: #272B41;
    background-color: #ffffff;
   
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: #272B41;
  background: #ffffff;
  cursor: not-allowed;
} 

.ant-picker-input > input[disabled] {
  color: #272B41;
  background-color: #ffffff;
  border-color: #E3E6EF;
  
} 
}

  footer {
    display: flex;
    flex-direction: column;

    button {
      margin-bottom: 8px;
    }
  }
`;

export const FormWrapper = Styled.div`
  .ant-picker {
    width: 100%;
  }

  .ant-form-item-has-error .ant-picker {
    border-color: red !important;
  }

 .ant-picker-input input {
    padding: 10px;
  }
    .ant-input[disabled] {
    color: #272B41;
    background-color: #ffffff;
   
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: #272B41;
  background: #ffffff;
  cursor: not-allowed;
} 

.ant-picker-input > input[disabled] {
  color: #272B41;
  background-color: #ffffff;
  border-color: #E3E6EF;
  
} 
}

  footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;

    button {
      margin-left: 16px;
    }
  }

  .ant-tree-select .ant-select-selector{
    height: 42px;
}
.tag-select-list{
    margin-bottom: -10px;
    .ant-select{
        margin-bottom: 10px;
    }
}
  .ant-select-selector{
    border-color: #E3E6EF !important;
}

.ant-select{
    &.ant-select-multiple{
        .ant-select-selection-item{
            ${({ theme }) => (!theme.rtl ? 'padding-left' : 'padding-right')}: 8px;
        }
    }
    .ant-select-selection-item{
        ${({ theme }) => (!theme.rtl ? 'padding-left' : 'padding-right')}: 10px !important;
    }
    &.ant-select-lg{
        height: 50px;
        line-height: 48px;
        .ant-select-selector{
            height: 50px !important;
            line-height: 48px;
        }
        .ant-select-selection-item{
            line-height: 48px !important;
            ${({ theme }) => (!theme.rtl ? 'padding-left' : 'padding-right')}: 8px;
        }
        &.ant-select-multiple.ant-select-lg .ant-select-selection-item{
            height: 32px;
            line-height: 32px !important;
        }
    }
    &.ant-select-multiple.ant-select-sm{
        .ant-select-selection-item{
            height: 16px;
            line-height: 14px;
            font-size: 11px;
        }
    }
}

.ant-form {
  .form-item{
      margin-bottom: 30px;
      label{
          font-weight: 500;
          display: block;
          margin-bottom: 15px;
      }
      .ant-cascader-picker{
          width: 100%;
          min-height: 48px;
          .ant-cascader-input{
              min-height: 48px;
          }
      }
  }
  .ant-input-affix-wrapper > input.ant-input{
      padding-top: 12px;
      padding-bottom: 12px;
  }
  .ant-input-affix-wrapper .ant-input-prefix svg{
      color: #9299B8;
  }
}
.ant-form-item-control-input{
  min-height: auto !important;
}
.ant-form-item{
  flex-flow: column;
  &:not(:last-child){
      margin-bottom: 26px;
  }
  &:last-child{
      margin-bottom: 0;
  }
  .ant-form-item-label{
      text-align: ${({ theme }) => (theme.rtl ? 'right' : 'left')};
      label{
          height: fit-content;
          margin-bottom: 6px;
      }
  }
  .ant-form-item-control-input{
      input,
      textarea{
          color: ${({ theme }) => theme['gray-color']};
          &:placeholder{
              color: ${({ theme }) => theme['light-color']};
          }
      }
      input[type="password"]{
          padding-top: 12px;
          padding-bottom: 12px;
      }
      .ant-picker-input input{
          padding: 12px;
      }
      button{
          height: 44px;
      }
      .ant-input-affix-wrapper{
          padding: 0 11px;
      }
  }
  .ant-select-single{
      .ant-select-selector{
          padding: 0 20px;
          height: 48px !important;
          border: 1px solid ${({ theme }) => theme['border-color-normal']};
          .ant-select-selection-item{
              line-height: 46px !important;
              padding: 0 !important;
          }
          .ant-select-selection-placeholder{
              line-height: 46px !important;
          }
      }
  }
}
.setting-form-actions{
  margin: 48px 0 14px;
  @media only screen and (max-width: 575px){
      margin: 40px 0 14px;
  }
  button{
      border-radius: 6px;
      height: 44px;
      margin-bottom: 14px;
      &.ant-btn-light{
          border: 1px solid ${({ theme }) => theme['border-color-light']};
          background-color: ${({ theme }) => theme['bg-color-light']};
      }
  }
}
.ant-form-item-control-input{
  .input-prepend{
      position: absolute;
      ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      height: 48px;
      border-radius: ${({ theme }) => (theme.rtl ? '0 4px 4px 0' : '4px 0 0 4px')};
      z-index: 10;
      border: 1px solid ${({ theme }) => theme['border-color-normal']};
      background-color: ${({ theme }) => theme['bg-color-light']};
      svg,
      i{
          color: ${({ theme }) => theme['gray-color']};
      }
  }
  .input-prepend-wrap{
      .ant-input-number{
          input{
              ${({ theme }) => (!theme.rtl ? 'padding-left' : 'padding-right')}: 70px;
          }
      }
  }
  .ant-input-number{
      width: 100% !important;
      border: 1px solid ${({ theme }) => theme['border-color-normal']};
  }
}
.add-record-form{
  margin: 25px 0 35px 0;
  
  .record-form-actions{
      padding-right: 40px;
  }
  .ant-btn{
      height: 44px;
      font-size: 14px;
      font-weight: 500;
  }
  .ant-radio-group{
      margin-bottom: -4px;
      .ant-radio-wrapper{
          margin-bottom: 4px;
      }
  }
}
.adTodo-form{
  .btn-adTodo {
      font-size: 14px;
  }
}

.sDash_form-action{
  margin: -7.5px;
  button{
      font-size: 14px;
      font-weight: 500;
      border-radius: 6px;
      margin: 7.5px;
      padding: 6.4px 19px;
      &.ant-btn-light{
          height: 44px;
          background-color: #F1F2F6;
          border-color: #F1F2F6;
      }
  }
  .ant-form-item{
      margin-bottom: 25px !important;
  }
  .ant-btn-light{
      background-color: #F8F9FB;
  }
}
.sDash_color-picker{
  border: 1px solid #E3E6EF;
  border-radius: 4px;
  padding: 11px 14px;
  input{
      width: 100%;
      border: 0 none;
      background-color: #fff;
      &::-webkit-color-swatch{
          min-height: 18px;
          border: 1px solid #C6D0DC;
      }
  }
}
`;


