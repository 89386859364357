import React from 'react';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';

import { IRotaProps } from '../../types';
import { TableButtonDelete } from './TableButtonDelete';
import { TableButtonEdit } from './TableButtonEdit';
import { TableButtonView } from './TableButtonView';
import { TableButtonRestore } from './TableButtonRestore';
import FeatherIcon from 'feather-icons-react';
import { NavLink, useHistory } from 'react-router-dom';
import { Button } from '../../../../components/buttons/buttons';

interface ITableColumnControllerProps {
  handleDeleteFunction: (rotaId: string) => Promise<void>;
  handleRestoreFunction: (rotaId: string) => Promise<void>;
  refetch: () => void;
  dataList?: IRotaProps[];
}

export function TableColumnController({
  handleDeleteFunction,
  handleRestoreFunction,
  refetch,
  dataList,
}: ITableColumnControllerProps) {
  const history = useHistory();

  const columns: ColumnsType<IRotaProps> = [
    {
      title: 'Sigla',
      dataIndex: 'sigla',
      sorter: true,
    },

    {
      title: 'Ações',
      dataIndex: 'actions',
      render: (_, item) => {
        return (
          <div className="table-actions">
            {item.status === 0 ? (
              <TableButtonRestore handleRestoreFunction={handleRestoreFunction} contactId={String(item.id)} />
            ) : (
              <>
                <TableButtonView rotaId={String(item.id)} refetch={refetch} data={item} />
                <Button
                  className="btn-icon"
                  type="info"
                  onClick={() => history.push('rotastrajetos/?idRota=' + item.id + '&rotaName=' + item.sigla)}
                  to="#"
                  shape="circle"
                >
                  <FeatherIcon icon="list" size={16} />
                </Button>
                <TableButtonEdit rotaId={String(item.id)} refetch={refetch} data={item} />

                <TableButtonDelete handleDeleteFunction={handleDeleteFunction} rotaId={String(item.id)} />
              </>
            )}
          </div>
        );
      },
    },
  ];

  const data: any[] = [];

  dataList?.forEach(item => {
    data.push({
      key: item.id,
      id: item.id,
      sigla: item.sigla,
      idCidade: String(item.idCidade),
      idVeiculo: String(item.idVeiculo),
      idContrato: item.idContrato,
      idCondutor: item.idCondutor,
      idRota: item.idRota,
      idMonitor: String(item.idMonitor),
      distancia: item.distancia,
      valorKM: item.valorKM,
      valorKMRecebido: item.valorKMRecebido,
      createdAt: moment(item.createdAt).format('DD/MM/YYYY'),
      status: item.status,
    });
  });

  return { columns, data };
}
