import React from 'react';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';

import { IItemProps } from '../../types';
import { TableButtonDelete } from './TableButtonDelete';
import { TableButtonEdit } from './TableButtonEdit';
import { TableButtonRestore } from './TableButtonRestore';

interface ITableColumnControllerProps {
  handleDeleteFunction: (contactId: string) => Promise<void>;
  handleRestoreFunction: (contactId: string) => Promise<void>;
  refetch: () => void;
  dataList?: IItemProps[];
}

export function TableColumnController({
  handleDeleteFunction,
  handleRestoreFunction,
  refetch,
  dataList,
}: ITableColumnControllerProps) {
  const columns: ColumnsType<IItemProps> = [
    {
      title: 'Nome',
      dataIndex: 'numero',
      sorter: true,
      render: (_, item) => <p>{item.pessoa.nome}</p>,
    },

    {
      title: 'Tipo de usuário',
      dataIndex: 'tipoUsuario',
      sorter: true,
      render: (_, item) => (
        <p>
          {item.tipoUsuario === 1 && 'Motorista'}
          {item.tipoUsuario === 2 && 'Monitor'}
          {item.tipoUsuario === 3 && 'Estudante'}
        </p>
      ),
    },

    {
      title: 'IP',
      dataIndex: 'ip',
      sorter: true,
    },
    {
      title: 'Último Login',
      dataIndex: 'numero',
      sorter: true,
      render: (_, item) => <p>{new Date(item.pessoa.updatedAt).toLocaleDateString()}</p>,
    },
  ];

  return { columns, data: dataList };
}
