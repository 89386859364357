import React, { useEffect } from 'react';
import Cookie from 'js-cookie';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';

import { ProtectedRoute } from '../components/utilities/protectedRoute';

import SignIn from '../pages/SignIn';

import { Cidades } from '../pages/cidades';

import { Nacionalidades } from '../pages/nacionalidades';
import { Sexos } from '../pages/sexos';

import { Condutores } from '../pages/condutores';
import { Proprietarios } from '../pages/proprietarios';
import { Bancos } from '../pages/bancos';
import { Escolacargos } from '../pages/escolascargos';
import { Escolaesferas } from '../pages/escolasesferas';
import { Escolagraus } from '../pages/escolasgraus';
import { Estadoscivis } from '../pages/estadoscivis';
import { Monitores } from '../pages/monitores';
import { Estudantes } from '../pages/estudantes';
import { Veiculos } from '../pages/veiculos';
import { Rotas } from '../pages/rotas';
import { Rotastrajetos } from '../pages/rotastrajetos';
import { Empresas } from '../pages/empresas';
import Dashboard from '../pages/Dashboard';
import { Contratos } from '../pages/contratos';
import { Escolas } from '../pages/escolas';
import { Operadoras } from '../pages/operadoras';
import { Gestors } from '../pages/gestores';

import { Usuarios } from '../pages/usuarios';
import { Pessoas } from '../pages/pessoas';
import { UsuariosApps } from '../pages/usuariosapps';
import { Ocorrencias } from '../pages/ocorrencias';
import { VisualizacaoDiario } from '../pages/visualizacaodiario';
import { DiarioEstudantes } from '../pages/diariosestudantes';
import { NotCity } from '../pages/notCity';
import { RelatorioRotas } from '../pages/relatoriorotas';
import { RelatorioAlunos } from '../pages/relatorioalunos';
import { RelatorioVeiculos } from '../pages/relatorioveiculos';
import { RelatorioVeiculosCondutor } from '../pages/relatorioveiculoscondutor';
import { RelatorioDemandas } from '../pages/relatoriodemandas';
import { RelatorioAlunosEscolasRotas } from '../pages/relatorioalunosescolasrotas';
import { RelatorioAlunosRotas } from '../pages/relatorioalunosrotas';
import { RelatorioAlunosEscolas } from '../pages/relatorioalunosescolas';
import { RelatorioVeiculosDiasRodados } from '../pages/relatorioveiculosdiasrodados';
import { useAuth } from '../hooks/useAuth';
import { Estudantes2 } from '../pages/estudantes2';
import { Profile } from '../pages/profile';

export function Routes() {
  const location = useLocation();
  const history = useHistory();
  const { user } = useAuth();

  const token = Cookie.get('whats-front-token');

  useEffect(() => {
    if (location.pathname === '/' && token) {
      history.push('/estudantes');
    }
  }, [history, token, location.pathname]);

  return (
    <Switch>
      <Route exact path="/" component={SignIn} />

      <ProtectedRoute path="/notcity" component={NotCity} />
      <ProtectedRoute path="/usuariosapps" component={UsuariosApps} />
      <ProtectedRoute path="/profile" component={Profile} />
      <ProtectedRoute path="/diarioestudantes/:id/:sigla/:data" component={DiarioEstudantes} />
      <ProtectedRoute path="/ocorrencias" component={Ocorrencias} />
      <ProtectedRoute path="/dashboard" component={Cidades} />
      <ProtectedRoute path="/cidades" component={Cidades} />
      <ProtectedRoute path="/nacionalidades" component={Nacionalidades} />
      <ProtectedRoute path="/sexos" component={Sexos} />
      <ProtectedRoute path="/condutores" component={Condutores} />
      <ProtectedRoute path="/monitores" component={Monitores} />
      <ProtectedRoute path="/estudantes" component={user?.role === 'ESCOLA' ? Estudantes2 : Estudantes} />
      <ProtectedRoute path="/veiculos" component={Veiculos} />
      <ProtectedRoute path="/proprietarios" component={Proprietarios} />
      <ProtectedRoute path="/bancos" component={Bancos} />
      <ProtectedRoute path="/escolascargos" component={Escolacargos} />
      <ProtectedRoute path="/escolasesferas" component={Escolaesferas} />
      <ProtectedRoute path="/escolasgraus" component={Escolagraus} />
      <ProtectedRoute path="/estados-civis" component={Estadoscivis} />
      <ProtectedRoute path="/rotas" component={Rotas} />
      <ProtectedRoute path="/rotastrajetos" component={Rotastrajetos} />
      <ProtectedRoute path="/empresas" component={Empresas} />
      <ProtectedRoute path="/contratos" component={Contratos} />
      <ProtectedRoute path="/escolas" component={Escolas} />
      <ProtectedRoute path="/operadoras" component={Operadoras} />
      <ProtectedRoute path="/gestores" component={Gestors} />
      <ProtectedRoute path="/usuarios" component={Usuarios} />
      <ProtectedRoute path="/pessoas" component={Pessoas} />
      <ProtectedRoute path="/visualizacaodiario" component={VisualizacaoDiario} />

      <ProtectedRoute path="/relatorio/rotas" component={RelatorioRotas} />
      <ProtectedRoute path="/relatorio/alunos" component={RelatorioAlunos} />
      <ProtectedRoute path="/relatorio/onibus" component={RelatorioVeiculos} />
      <ProtectedRoute path="/relatorio/demandas" component={RelatorioDemandas} />
      <ProtectedRoute path="/relatorio/alunosescolas" component={RelatorioAlunosEscolas} />
      <ProtectedRoute path="/relatorio/veiculodiasrodados" component={RelatorioVeiculosDiasRodados} />
      <ProtectedRoute path="/relatorio/alunosescolasrotas" component={RelatorioAlunosEscolasRotas} />
      <ProtectedRoute path="/relatorio/alunosrotas" component={RelatorioAlunosRotas} />
      <ProtectedRoute path="/relatorio/onibus-condutor" component={RelatorioVeiculosCondutor} />
    </Switch>
  );
}
