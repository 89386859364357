import React, { useState } from "react";
// import { Modal } from "antd"; 
import FeatherIcon from "feather-icons-react";

import { Button } from "../../../../components/buttons/buttons";
import { Modal } from "antd";
import { Form } from "../MonitorForm";
import moment from "moment";


interface ITableButtonEditProps {
  monitorId: string;
  refetch: () => void;
  data: IFetchProps;
}

interface IFetchProps {
  curriculo: string;
  comprovanteResidencia: string;
  idPessoa: string;
  antecedentes: string;
  certidaoCriminal: string;
  certidaoCriminalVencimento: moment.Moment;
  atividadeInicio: moment.Moment;
  atividadeFim: moment.Moment;
  
}

export function TableButtonView({ monitorId, refetch, data }: ITableButtonEditProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <Button 
        className="btn-icon" 
        type="info" 
        onClick={() => setModalIsOpen(true)} 
        to="#" 
        shape="circle"
        >
        <FeatherIcon icon="search" size={16} />
      </Button>

      <Modal
        title="Visualizar"
        visible={modalIsOpen}
        onCancel={() => setModalIsOpen(false)}
        confirmLoading={true}
        footer={null}
        maskClosable={false}
        width={1200}
        style={{marginTop: -60}}
        bodyStyle={{padding: 0}}
      > 
        <Form 
          formType="view"
          monitorId={monitorId}
          initialFields={{
            curriculo: data.curriculo,
            comprovanteResidencia: data.comprovanteResidencia,
            idPessoa: String(data.idPessoa),
            antecedentes: data.antecedentes,
            certidaoCriminal: data.certidaoCriminal,
            certidaoCriminalVencimento: moment(data.certidaoCriminalVencimento),
            atividadeInicio: moment(data.atividadeInicio),
            atividadeFim: moment(data.atividadeFim),
          

          }}
          setModalIsOpen={setModalIsOpen} 
          refetch={refetch} 
        />
      </Modal>
    </>
  );
}