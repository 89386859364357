import React, { useRef, useState } from 'react';

import { Row, Col, Button, Input, Form as AntdForm } from 'antd';
import { CardWrapper, FormWrapper } from '../styles';
import { api } from '../../../services/api';
import { getSubDomain } from '../../../hooks/getSubDomain';
import { UploadComponent } from '../../../components/UploadComponent';
import { IHandleSubmitProps } from '../types';
import { UploadFile } from 'antd/lib/upload/interface';
import toast from 'react-hot-toast';
import { dados } from '../../../utility/env';
import { SelectPessoa } from '../../../components/Select/SelectPessoa';
import { SelectBanco } from '../../../components/Select/SelectBanco';

interface IFormInitialFields {
  bancoAgencia: string;
  comprovanteResidencia: string;
  idPessoa: string;
  bancoConta: string;
  idEmpresa: string;
  idBanco: string;
}

interface IFormComponent {
  formType: 'put' | 'post' | 'view';
  initialFields?: IFormInitialFields;
  refetch: () => void;
  proprietarioId?: string;
  setModalIsOpen: (event: boolean) => void;
}

export function Form({
  formType,
  setModalIsOpen,
  refetch,
  proprietarioId,
  initialFields = {
    bancoAgencia: '',
    comprovanteResidencia: '',
    idPessoa: '',
    bancoConta: '',
    idEmpresa: '',
    idBanco: '',
  },
}: IFormComponent) {
  const [isLoading, setIsLoading] = useState(false);

  const [fileListcomprovanteResidencia, setFileListcomprovanteResidencia] = useState<UploadFile[]>(() => {
    if (initialFields.comprovanteResidencia) {
      return [
        {
          uid: initialFields.comprovanteResidencia,
          name: initialFields.comprovanteResidencia,
          status: 'done',
          url: dados.imgbaseurl + initialFields.comprovanteResidencia,
        },
      ];
    } else return [] as UploadFile[];
  });

  const formRef = useRef<any>(null);

  function clearFieldsAndCloseModal() {
    setModalIsOpen(false);
  }

  async function handleSubmit(data: IHandleSubmitProps) {
    setIsLoading(true);

    if (fileListcomprovanteResidencia.length <= 0) {
      toast.error('É necessário indexar um arquivo');
      setIsLoading(false);
      return;
    }

    console.log(fileListcomprovanteResidencia);
    const formData = {
      ...data,

      comprovanteResidencia: fileListcomprovanteResidencia[0].response
        ? fileListcomprovanteResidencia[0].response.filename
        : fileListcomprovanteResidencia[0].name,
    };

    try {
      if (formType === 'post') {
        await api.post(getSubDomain() + 'proprietarios', formData);
      } else {
        if (proprietarioId) await api.put(getSubDomain() + `proprietarios/${proprietarioId}`, formData);
      }

      setModalIsOpen(false);

      formRef.current.resetFields();
      setFileListcomprovanteResidencia([]);
    } catch (err) {
      console.log(err);
    }

    setIsLoading(false);
    refetch();
  }

  return (
    <FormWrapper>
      <AntdForm
        name="basic"
        onFinish={handleSubmit}
        style={{ padding: '30px' }}
        layout="vertical"
        initialValues={initialFields}
        ref={formRef}
      >
        <Row gutter={40}>
          <Col span={12}>
            <CardWrapper type="inner" title="Informações Gerais">
              <SelectPessoa
                name="idPessoa"
                defaultValue={initialFields.idPessoa}
                title="Pessoa"
                disabled={formType === 'view' ? true : false}
                postUrl={formType !== 'post' ? undefined : getSubDomain() + 'pessoas'}
                fetchUrl="pessoas"
              />

              <AntdForm.Item label="Comprovante de Residência" name="comprovanteResidencia">
                <UploadComponent
                  maxCount={1}
                  disabled={formType === 'view' ? true : false}
                  fileList={fileListcomprovanteResidencia}
                  setFileList={setFileListcomprovanteResidencia}
                />
              </AntdForm.Item>
            </CardWrapper>
          </Col>

          <Col span={12}>
            <CardWrapper type="inner" title="Dados Bancários">
              <SelectBanco
                name="idBanco"
                defaultValue={initialFields.idBanco}
                title="Banco"
                disabled={formType === 'view' ? true : false}
                //postUrl="bancos"
                fetchUrl="bancos"
              />

              <AntdForm.Item
                label="Agência"
                name="bancoAgencia"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input placeholder="" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>
              <AntdForm.Item
                label="Conta Corrente"
                name="bancoConta"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input placeholder="" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>
            </CardWrapper>
          </Col>
        </Row>
        {formType == 'view' ? (
          <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
            <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
              Fechar
            </Button>
          </footer>
        ) : (
          <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
            <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
              Voltar
            </Button>

            <Button type="primary" htmlType="submit" loading={isLoading}>
              Salvar
            </Button>
          </footer>
        )}
      </AntdForm>
    </FormWrapper>
  );
}
