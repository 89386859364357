import React, { useRef, useState } from 'react';

import { Row, Col, Button, Input, Form as AntdForm, DatePicker } from 'antd';
import { CardWrapper, FormWrapper } from '../styles';
import { api } from '../../../services/api';
import { getSubDomain } from '../../../hooks/getSubDomain';
import { SelectSegmento } from '../../../components/Select';
import { UploadComponent } from '../../../components/UploadComponent';
import { IHandleSubmitProps } from '../types';
import { UploadFile } from 'antd/lib/upload/interface';
import toast from 'react-hot-toast';
import { MultiSelect } from '../../../components/MultiSelect';
import { dados } from '../../../utility/env';
import { SelectPessoa } from '../../../components/Select/SelectPessoa';
import moment from 'moment';

interface IFormInitialFields {
  curriculo: string;
  comprovanteResidencia: string;
  idPessoa: string;
  antecedentes: string;
  certidaoCriminal: string;
  certidaoCriminalVencimento: moment.Moment;
  atividadeInicio: moment.Moment;
  atividadeFim: moment.Moment;
}

interface IFormComponent {
  formType: 'put' | 'post' | 'view';
  initialFields?: IFormInitialFields;
  refetch: () => void;
  monitorId?: string;
  setModalIsOpen: (event: boolean) => void;
}

export function Form({
  formType,
  setModalIsOpen,
  refetch,
  monitorId,
  initialFields = {
    curriculo: '',

    comprovanteResidencia: '',
    idPessoa: '',
    antecedentes: '',
    certidaoCriminal: '',
    certidaoCriminalVencimento: moment(),
    atividadeInicio: moment(),
    atividadeFim: moment(),
  },
}: IFormComponent) {
  const [isLoading, setIsLoading] = useState(false);
  const [fileListaCurriculo, setFileListaCurriculo] = useState<UploadFile[]>(() => {
    if (initialFields.curriculo) {
      return [
        {
          uid: initialFields.curriculo,
          name: initialFields.curriculo,
          status: 'done',
          url: dados.imgbaseurl + initialFields.curriculo,
        },
      ];
    } else return [] as UploadFile[];
  });

  const [fileListcomprovanteResidencia, setFileListcomprovanteResidencia] = useState<UploadFile[]>(() => {
    if (initialFields.comprovanteResidencia) {
      return [
        {
          uid: initialFields.comprovanteResidencia,
          name: initialFields.comprovanteResidencia,
          status: 'done',
          url: dados.imgbaseurl + initialFields.comprovanteResidencia,
        },
      ];
    } else return [] as UploadFile[];
  });

  const [fileListantecedentes, setFileListantecedentes] = useState<UploadFile[]>(() => {
    if (initialFields.antecedentes) {
      return [
        {
          uid: initialFields.antecedentes,
          name: initialFields.antecedentes,
          status: 'done',
          url: dados.imgbaseurl + initialFields.antecedentes,
        },
      ];
    } else return [] as UploadFile[];
  });

  const [fileListCertidaoCriminal, setFileListCertidaoCriminal] = useState<UploadFile[]>(() => {
    if (initialFields.certidaoCriminal) {
      return [
        {
          uid: initialFields.certidaoCriminal,
          name: initialFields.certidaoCriminal,
          status: 'done',
          url: dados.imgbaseurl + initialFields.certidaoCriminal,
        },
      ];
    } else return [] as UploadFile[];
  });

  const formRef = useRef<any>(null);

  function clearFieldsAndCloseModal() {
    setModalIsOpen(false);
  }

  async function handleSubmit(data: IHandleSubmitProps) {
    setIsLoading(true);

    if (fileListaCurriculo.length <= 0) {
      toast.error('É necessário indexar um Currículo');
      setIsLoading(false);
      return;
    }

    if (fileListcomprovanteResidencia.length <= 0) {
      toast.error('É necessário indexar um Comprovante de Residência');
      setIsLoading(false);
      return;
    }

    if (fileListantecedentes.length <= 0) {
      toast.error('É necessário indexar uma Lista de Antecedentes');
      setIsLoading(false);
      return;
    }

    if (fileListCertidaoCriminal.length <= 0) {
      toast.error('É necessário indexar uma Certidão Criminal');
      setIsLoading(false);
      return;
    }

    const formData = {
      ...data,
      curriculo: fileListaCurriculo[0].response ? fileListaCurriculo[0].response.filename : fileListaCurriculo[0].name,

      comprovanteResidencia: fileListcomprovanteResidencia[0].response
        ? fileListcomprovanteResidencia[0].response.filename
        : fileListcomprovanteResidencia[0].name,
      antecedentes: fileListantecedentes[0].response
        ? fileListantecedentes[0].response.filename
        : fileListantecedentes[0].name,
      certidaoCriminal: fileListCertidaoCriminal[0].response
        ? fileListCertidaoCriminal[0].response.filename
        : fileListCertidaoCriminal[0].name,
    };

    try {
      if (formType === 'post') {
        await api.post(getSubDomain() + 'monitors', formData);
      } else {
        if (monitorId) await api.put(getSubDomain() + `monitors/${monitorId}`, formData);
      }

      setModalIsOpen(false);

      formRef.current.resetFields();
      setFileListaCurriculo([]);
    } catch (err) {
      console.log(err);
    }

    setIsLoading(false);
    refetch();
  }

  return (
    <FormWrapper>
      <AntdForm
        name="basic"
        onFinish={handleSubmit}
        style={{ padding: '30px' }}
        layout="vertical"
        initialValues={initialFields}
        ref={formRef}
      >
        {console.log(initialFields.idPessoa)}
        <Row gutter={40}>
          <Col span={12}>
            <CardWrapper type="inner" title="Informações Gerais">
              <SelectPessoa
                name="idPessoa"
                defaultValue={initialFields.idPessoa}
                title="Pessoa"
                disabled={formType === 'view' ? true : false}
                postUrl={formType !== 'post' ? undefined : getSubDomain() + 'pessoas'}
                fetchUrl="pessoas"
              />

              <AntdForm.Item label="Comprovante de Residência" name="comprovanteResidencia">
                <UploadComponent
                  maxCount={1}
                  disabled={formType === 'view' ? true : false}
                  fileList={fileListcomprovanteResidencia}
                  setFileList={setFileListcomprovanteResidencia}
                />
              </AntdForm.Item>

              <AntdForm.Item label="Antecedentes Criminais" name="antecedentes">
                <UploadComponent
                  maxCount={1}
                  disabled={formType === 'view' ? true : false}
                  fileList={fileListantecedentes}
                  setFileList={setFileListantecedentes}
                />
              </AntdForm.Item>

              <AntdForm.Item label="Currículo" name="curriculo">
                <UploadComponent
                  maxCount={1}
                  disabled={formType === 'view' ? true : false}
                  fileList={fileListaCurriculo}
                  setFileList={setFileListaCurriculo}
                />
              </AntdForm.Item>
            </CardWrapper>
          </Col>

          <Col span={12}>
            <CardWrapper type="inner" title="Informações Gerais">
              <AntdForm.Item label="Certidão Criminal" name="certidaoCriminal">
                <UploadComponent
                  maxCount={1}
                  disabled={formType === 'view' ? true : false}
                  fileList={fileListCertidaoCriminal}
                  setFileList={setFileListCertidaoCriminal}
                />
              </AntdForm.Item>

              <AntdForm.Item
                label="Data de Vencimento da Certidão Criminal"
                name="certidaoCriminalVencimento"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder="Selecione uma Data"
                  disabled={formType === 'view' ? true : false}
                />
              </AntdForm.Item>

              <AntdForm.Item
                label="Início da Atividade"
                name="atividadeInicio"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder="Selecione uma Data"
                  disabled={formType === 'view' ? true : false}
                />
              </AntdForm.Item>

              <AntdForm.Item
                label="Fim da Atividade"
                name="atividadeFim"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder="Selecione uma Data"
                  disabled={formType === 'view' ? true : false}
                />
              </AntdForm.Item>
            </CardWrapper>
          </Col>
        </Row>
        {formType == 'view' ? (
          <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
            <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
              Fechar
            </Button>
          </footer>
        ) : (
          <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
            <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
              Voltar
            </Button>

            <Button type="primary" htmlType="submit" loading={isLoading}>
              Salvar
            </Button>
          </footer>
        )}
      </AntdForm>
    </FormWrapper>
  );
}
