import React from 'react';
import { Col, Radio } from 'antd';

interface Props {
  setStatus: any;
}

export function StatusRadioGroup({ setStatus }: Props) {
  return (
    <Col lg={14} xs={24}>
      <div className="table-toolbox-menu">
        <span className="toolbox-menu-title"> Status:</span>

        <Radio.Group onChange={e => setStatus(e.target.value)} defaultValue="1">
          =<Radio.Button value="1">Ativos</Radio.Button>
          <Radio.Button value="0">Inativos</Radio.Button>
          <Radio.Button value={undefined}>Todos</Radio.Button>
        </Radio.Group>
      </div>
    </Col>
  );
}
