import React, { useState } from 'react';
import { Table as AntdTable } from 'antd';
import toast from 'react-hot-toast';

import { IFetchPropsRotas } from '../../types';
import { api } from '../../../../services/api';
import { getSubDomain } from '../../../../hooks/getSubDomain';
import { TableColumnController } from './tableColumnController';
import { IFetchPropsVeiculos } from '../../../veiculos/types';

interface ITableComponentProps {
  dataFetch?: IFetchPropsVeiculos;
  isLoading: boolean;
  refetch: () => void;
  currentPage: number;
  itensPerPage: number;
  setCurrentPage: (event: number) => void;
  setColumnOrdenation: (event: string) => void;
  setItensPerPage: (event: number) => void;
  totalItens: number;
}

export function TableComponent({
  refetch,
  dataFetch,
  totalItens,
  isLoading,
  itensPerPage,
  currentPage,
  setCurrentPage,
  setColumnOrdenation,
  setItensPerPage,
}: ITableComponentProps) {
  const pageSizeOptions = ['10', '20', '30'];

  function onChange(pagination: any, filters: any, sorter: any) {
    setCurrentPage(pagination.current);
    setItensPerPage(pagination.pageSize);

    if (sorter.order === 'descend') {
      setColumnOrdenation(`${sorter.field}:DESC`);
    } else if (sorter.order === 'ascend') {
      setColumnOrdenation(`${sorter.field}:ASC`);
    } else {
      setColumnOrdenation(``);
    }
  }
  const { columns, data } = TableColumnController({
    dataList: dataFetch,
  });

  return (
    <AntdTable
      loading={isLoading}
      showSorterTooltip={false}
      onChange={onChange}
      dataSource={data}
      columns={columns}
      pagination={{
        current: currentPage,
        pageSize: itensPerPage,
        pageSizeOptions,
        total: totalItens,
        showSizeChanger: true,
      }}
    />
  );
}
