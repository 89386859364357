import React, { useState } from 'react';
// import { Modal } from "antd";
import FeatherIcon from 'feather-icons-react';

import { Button } from '../../../../components/buttons/buttons';
import { Modal } from 'antd';
import { Form } from '../RotastrajetoForm';
import { useFetch } from '../../../../hooks/useFetch';

interface ITableButtonEditProps {
  rotastrajetoId: string;
  refetch: () => void;
  data: IFetchProps;
}

interface IFetchProps {
  idRota: string;
  etapa: string;
  descricao: string;
  latitude: string;
  longitude: string;
}

export function TableButtonEdit({ rotastrajetoId, refetch, data }: ITableButtonEditProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <Button className="btn-icon" type="info" onClick={() => setModalIsOpen(true)} to="#" shape="circle">
        <FeatherIcon icon="edit" size={16} />
      </Button>

      <Modal
        title="Editar"
        visible={modalIsOpen}
        onCancel={() => setModalIsOpen(false)}
        confirmLoading={true}
        footer={null}
        maskClosable={false}
        width={1200}
        style={{ marginTop: -60 }}
        bodyStyle={{ padding: 0 }}
      >
        <Form
          formType="put"
          rotastrajetoId={rotastrajetoId}
          initialFields={{
            idRota: String(data.idRota),
            etapa: data.etapa,
            descricao: data.descricao,
            latitude: data.latitude,
            longitude: data.longitude,
          }}
          setModalIsOpen={setModalIsOpen}
          refetch={refetch}
        />
      </Modal>
    </>
  );
}
