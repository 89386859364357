import React from 'react';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';

import { IProprietarioProps } from '../../types';
import { TableButtonDelete } from './TableButtonDelete';
import { TableButtonEdit } from './TableButtonEdit';
import { TableButtonView } from './TableButtonView';
import { TableButtonRestore } from './TableButtonRestore';

interface ITableColumnControllerProps {
  handleDeleteFunction: (proprietarioId: string) => Promise<void>;
  handleRestoreFunction: (proprietarioId: string) => Promise<void>;
  refetch: () => void;
  dataList?: IProprietarioProps[];
}

export function TableColumnController({
  handleDeleteFunction,
  handleRestoreFunction,
  refetch,
  dataList,
}: ITableColumnControllerProps) {
  const columns: ColumnsType<IProprietarioProps> = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      sorter: true,
    },

    {
      title: 'Ações',
      dataIndex: 'actions',
      render: (_, item) => {
        return (
          <div className="table-actions">
            {item.status === 0 ? (
              <TableButtonRestore handleRestoreFunction={handleRestoreFunction} contactId={String(item.id)} />
            ) : (
              <>
                <TableButtonView proprietarioId={String(item.id)} refetch={refetch} data={item} />
                <TableButtonEdit proprietarioId={String(item.id)} refetch={refetch} data={item} />

                <TableButtonDelete handleDeleteFunction={handleDeleteFunction} proprietarioId={String(item.id)} />
              </>
            )}
          </div>
        );
      },
    },
  ];

  const data: any[] = [];

  dataList?.forEach(item => {
    data.push({
      key: item.id,
      id: item.id,
      nome: item.pessoa.nome,
      createdAt: moment(item.createdAt).format('DD/MM/YYYY'),
      idPessoa: item.idPessoa,
      bancoAgencia: item.bancoAgencia,
      comprovanteResidencia: item.comprovanteResidencia,
      bancoConta: item.bancoConta,
      idBanco: item.idBanco,
      idEmpresa: item.idEmpresa,
      status: item.status,
    });
  });

  return { columns, data };
}
