import React, { useRef, useState } from "react";

import { Row, Col, Button, Input, Form as AntdForm } from "antd";
import { CardWrapper, FormWrapper } from "../styles";
import { api } from "../../../services/api";
import { getSubDomain } from "../../../hooks/getSubDomain";
import { SelectSegmento } from "../../../components/Select";
import { UploadComponent } from "../../../components/UploadComponent";
import { IHandleSubmitProps } from "../types";
import { UploadFile } from "antd/lib/upload/interface";
import toast from "react-hot-toast";
import { MultiSelect } from "../../../components/MultiSelect";
import { dados } from "../../../utility/env";
import { SelectPessoa } from "../../../components/Select/SelectPessoa";
import { SelectBanco } from "../../../components/Select/SelectBanco";

interface IFormInitialFields {
  bancoAgencia: string;
  contratoSocial: string;
  idPessoa: string;
  cnpj: string;
  razaoSocial: string;
  bancoConta: string;
  idBanco: string;
  

}

interface IFormComponent {
  formType: "put" | "post" | "view",
  initialFields?: IFormInitialFields;
  refetch: () => void;
  empresaId?: string;
  setModalIsOpen: (event: boolean) => void;
}

export function Form({ 
  formType,
  setModalIsOpen, 
  refetch, 
  empresaId,
  initialFields = { 
    bancoAgencia: "",
  contratoSocial: "",
  idPessoa: "",
  cnpj: "",
  razaoSocial: "",
  bancoConta: "",
  idBanco: "",
    
  
  } 
}: IFormComponent) {
  const [isLoading, setIsLoading] = useState(false);


  const [fileListcontratoSocial, setFileListcontratoSocial] = useState<UploadFile[]>(() => {
    if(initialFields.contratoSocial) {
      return [{
        uid: initialFields.contratoSocial,
        name: initialFields.contratoSocial,
        status: 'done',
        url: dados.imgbaseurl + initialFields.contratoSocial,
      }]
    }

    else return [] as UploadFile[];
  })

  


  const formRef = useRef<any>(null);

  function clearFieldsAndCloseModal() {
    setModalIsOpen(false);
  }

  async function handleSubmit(data: IHandleSubmitProps) {
    setIsLoading(true);

   

    if(fileListcontratoSocial.length <= 0) {
      toast.error("É necessário indexar um arquivo");
      setIsLoading(false);
      return;
    }


    const formData = {
      ...data,
      contratoSocial: fileListcontratoSocial[0].response? fileListcontratoSocial[0].response.filename : fileListcontratoSocial[0].name,
     
      
        }

    try {
      if(formType === "post") {
        await api.post(getSubDomain() + "empresas", formData);
      } else {
        if(empresaId) await api.put(getSubDomain() + `empresas/${empresaId}`, formData);
      }

      setModalIsOpen(false);
       
      formRef.current.resetFields()
      setFileListcontratoSocial([]);
    } catch(err) {
      console.log(err)
    }
    
    setIsLoading(false);
    refetch();
  }

  return (
    <FormWrapper>
      <AntdForm
        name="basic"
        onFinish={handleSubmit}
        style={{ padding: '30px' }}
        layout="vertical"
        initialValues={initialFields}
        ref={formRef}
      >
        <Row gutter={40}>
          <Col span={12}>
            <CardWrapper type="inner" title="Informações Gerais">
              <SelectPessoa
                name="idPessoa"
                defaultValue={initialFields.idPessoa}
                disabled={formType === 'view' ? true : false}
                title="Responsável"
                postUrl={getSubDomain() + 'pessoas'}
                fetchUrl="pessoas"
              />

              <AntdForm.Item label="Contrato Social" name="contratoSocial">
                <UploadComponent
                  maxCount={1}
                  disabled={formType === 'view' ? true : false}
                  fileList={fileListcontratoSocial}
                  setFileList={setFileListcontratoSocial}
                />
              </AntdForm.Item>

              <AntdForm.Item
                label="Razão Social"
                name="razaoSocial"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input placeholder="" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>

              <AntdForm.Item label="CNPJ" name="cnpj" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <Input placeholder="" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>
            </CardWrapper>
          </Col>

          <Col span={12}>
            <CardWrapper type="inner" title="Dados Bancários">
              <SelectBanco
                name="idBanco"
                defaultValue={initialFields.idBanco}
                title="Banco"
                disabled={formType === 'view' ? true : false}
                //postUrl="bancos"
                fetchUrl="bancos"
              />

              <AntdForm.Item
                label="Agência"
                name="bancoAgencia"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input placeholder="" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>
              <AntdForm.Item
                label="Conta Corrente"
                name="bancoConta"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input placeholder="" disabled={formType === 'view' ? true : false} />
              </AntdForm.Item>
            </CardWrapper>
          </Col>
        </Row>
        {formType == 'view' ? (
          <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
            <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
              Fechar
            </Button>
          </footer>
        ) : (
          <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
            <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
              Voltar
            </Button>

            <Button type="primary" htmlType="submit" loading={isLoading}>
              Salvar
            </Button>
          </footer>
        )}
      </AntdForm>
    </FormWrapper>
  );
}
