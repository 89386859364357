import React, { useState } from "react";
// import { Modal } from "antd"; 
import FeatherIcon from "feather-icons-react";
import moment from "moment";
import { Button } from "../../../../components/buttons/buttons";
import { Modal } from "antd";
import { Form } from "../ContratoForm";
import { useFetch } from "../../../../hooks/useFetch";
import { format } from "date-fns";

interface ITableButtonEditProps {
  veiculoId: string;
  refetch: () => void;
  data: IFetchProps;
}

interface IFetchProps {
  idProprietario: string;
  idOperadora: string;
  numero: string;
  contrato: string;
  contratoInicio: moment.Moment;
  contratoFim: moment.Moment;
  
}

export function TableButtonEdit({ veiculoId, refetch, data }: ITableButtonEditProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <Button 
        className="btn-icon" 
        type="info" 
        onClick={() => setModalIsOpen(true)} 
        to="#" 
        shape="circle"
        >
        <FeatherIcon icon="edit" size={16} />
      </Button>

      <Modal
        title="Editar"
        visible={modalIsOpen}
        onCancel={() => setModalIsOpen(false)}
        confirmLoading={true}
        footer={null}
        maskClosable={false}
        width={1200}
        style={{marginTop: -60}}
        bodyStyle={{padding: 0}}
      > 
        <Form 
          formType="put"
          veiculoId={veiculoId}
          initialFields={{
            idProprietario: String(data.idProprietario),
            idOperadora: String(data.idOperadora),
            numero: data.numero,
            contrato:data.contrato,
            contratoInicio: moment(data.contratoInicio),
            contratoFim: moment(data.contratoFim),
          

          }}
          setModalIsOpen={setModalIsOpen} 
          refetch={refetch} 
        />
      </Modal>
    </>
  );
}