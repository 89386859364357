import React, { useState } from "react";
// import { Modal } from "antd"; 
import FeatherIcon from "feather-icons-react";

import { Button } from "../../../../components/buttons/buttons";
import { Modal } from "antd";
import { Form } from "../RotaForm";
import { useFetch } from "../../../../hooks/useFetch";

interface ITableButtonEditProps {
  rotaId: string;
  refetch: () => void;
  data: IFetchProps;
}

interface IFetchProps {
  idCidade: string;
  sigla: string;
  idContrato: string;
  idCondutor: string;
  idRota: string;
  idMonitor: string;
  distancia: string;
  valorKM: string;
  valorKMRecebido: string;
  idVeiculo: string;
}

export function TableButtonEdit({ rotaId, refetch, data }: ITableButtonEditProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <Button 
        className="btn-icon" 
        type="info" 
        onClick={() => setModalIsOpen(true)} 
        to="#" 
        shape="circle"
        >
        <FeatherIcon icon="edit" size={16} />
      </Button>

      <Modal
        title="Editar"
        visible={modalIsOpen}
        onCancel={() => setModalIsOpen(false)}
        confirmLoading={true}
        footer={null}
        maskClosable={false}
        width={1200}
        style={{marginTop: -60}}
        bodyStyle={{padding: 0}}
      > 
        <Form 
          formType="put"
          rotaId={rotaId}
          initialFields={{

            idCidade: String(data.idCidade),
            sigla: data.sigla,
            idContrato: String(data.idContrato),
            idCondutor: String(data.idCondutor),
            idRota: String(data.idRota),
            idVeiculo: String(data.idVeiculo),
            idMonitor: String(data.idMonitor),
            distancia: data.distancia,
            valorKM: data.valorKM,
            valorKMRecebido: data.valorKMRecebido,
            

          
          

          }}
          setModalIsOpen={setModalIsOpen} 
          refetch={refetch} 
        />
      </Modal>
    </>
  );
}