import React, { useRef, useState } from 'react';

import { Button, Form as AntdForm, Input } from 'antd';
import FeatherIcon from 'feather-icons-react';
import toast from 'react-hot-toast';
import { getSubDomain } from '../../../hooks/getSubDomain';
import { api } from '../../../services/api';
import { CardWrapper, FormWrapper } from '../styles';
import { IHandleSubmitProps } from '../types';

interface IFormInitialFields {
  numero: string;
  nome: string;
  status: number;
}

interface IFormComponent {
  formType: 'put' | 'post' | 'view';
  initialFields?: IFormInitialFields;
  refetch: () => void;

  contactId?: string;
  setModalIsOpen: (event: boolean) => void;
}

export function Form({
  formType,
  setModalIsOpen,
  refetch,
  contactId,
  initialFields = {
    numero: '',
    nome: '',
    status: 1,
  },
}: IFormComponent) {
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef<any>(null);
  function clearFieldsAndCloseModal() {
    setModalIsOpen(false);
  }

  async function handleSubmit(data: IHandleSubmitProps) {
    setIsLoading(true);

    try {
      if (formType === 'post') {
        await api.post(getSubDomain() + 'bancos', data);
        toast.success('Salvo com sucesso');
      } else {
        if (contactId) await api.put(getSubDomain() + `bancos/${contactId}`, data);
        toast.success('Salvo com sucesso');
      }
      setModalIsOpen(false);

      formRef.current.resetFields();
    } catch (err) {
      console.log(err);
      toast.error('Erro ao Salvar');
    }

    setIsLoading(false);
    refetch();
  }

  return (
    <FormWrapper>
      <AntdForm
        name="basic"
        onFinish={handleSubmit}
        style={{ padding: '30px' }}
        layout="vertical"
        initialValues={initialFields}
        ref={formRef}
      >
        <CardWrapper type="inner" title="Informações Gerais">
          <AntdForm.Item
            label="Número"
            name="numero"
            rules={[
              { required: true, message: 'Campo Obrigatório!' },
              { max: 5, message: 'O campo deve conter no máximo 5 caracteres.' },
            ]}
            wrapperCol={{ span: 16 }}
          >
            <Input placeholder="Número" />
          </AntdForm.Item>

          <AntdForm.Item
            label="Banco"
            name="nome"
            rules={[
              { required: true, message: 'Campo Obrigatório!' },
              { max: 45, message: 'O campo deve conter no máximo 45 caracteres.' },
            ]}
          >
            <Input placeholder="Banco" />
          </AntdForm.Item>
        </CardWrapper>

        <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
          <Button type="default" size="large" htmlType="button" onClick={clearFieldsAndCloseModal}>
            Cancelar
          </Button>

          <Button type="primary" size="large" htmlType="submit" loading={isLoading}>
            <FeatherIcon icon="check" size={14} style={{ marginRight: 10 }} />
            Salvar
          </Button>
        </footer>
      </AntdForm>
    </FormWrapper>
  );
}
