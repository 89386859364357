import React, { useState } from "react";
// import { Modal } from "antd"; 
import FeatherIcon from "feather-icons-react";

import { Button } from "../../../../components/buttons/buttons";
import { Modal } from "antd";
import { Form } from "../ItemForm";
import { useFetch } from "../../../../hooks/useFetch";

interface ITableButtonEditProps {
  contactId: string;
  refetch: () => void;
  data: IFetchProps;
}

interface IFetchProps {
  nome: string ;
  email:  string;
  phone:  string;
  role: string;
  idEscola: number;
  password: string;
  status: number;
}

export function TableButtonEdit({ contactId, refetch, data }: ITableButtonEditProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <Button className="btn-icon" type="info" onClick={() => setModalIsOpen(true)} to="#" shape="circle">
        <FeatherIcon icon="edit" size={16} />
      </Button>

      <Modal
        title="Editar Usuario"
        visible={modalIsOpen}
        onCancel={() => setModalIsOpen(false)}
        confirmLoading={true}
        footer={null}
        maskClosable={false}
        width={600}
        style={{ marginTop: -60 }}
        bodyStyle={{ padding: 0 }}
      >
        <Form
          formType="put"
          contactId={contactId}
          initialFields={{
            nome: data.nome,
            email: data.email,
            role: data.role,
            idEscola: data.idEscola ? data.idEscola.toString() : '',
            phone: data.phone,
            password: data.password,
            //tag: "1"
          }}
          setModalIsOpen={setModalIsOpen}
          refetch={refetch}
        />
      </Modal>
    </>
  );
}