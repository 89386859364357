import React, { useRef, useState } from 'react';

import { Form as AntdForm, Button, Input, Select } from 'antd';
import FeatherIcon from 'feather-icons-react';
import toast from 'react-hot-toast';
import { SelectEscola } from '../../../components/Select/SelectEscola';
import { getSubDomain } from '../../../hooks/getSubDomain';
import { api } from '../../../services/api';
import { CardWrapper, FormWrapper } from '../styles';
import { IHandleSubmitProps } from '../types';

interface IFormInitialFields {
  nome: string;
  email: string;
  phone: string;
  role: string;
  password: string;
  idEscola: string | number;
}

interface IFormComponent {
  formType: 'put' | 'post' | 'view';
  initialFields?: IFormInitialFields;
  refetch: () => void;

  contactId?: string;
  setModalIsOpen: (event: boolean) => void;
}

export function Form({
  formType,
  setModalIsOpen,
  refetch,
  contactId,
  initialFields = {
    email: '',
    phone: '',
    role: '',
    password: '',
    idEscola: '',
    nome: '',
  },
}: IFormComponent) {
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef<any>(null);
  const [status, setStatus] = useState(initialFields.role);

  function clearFieldsAndCloseModal() {
    setModalIsOpen(false);
  }

  async function handleSubmit(data: IHandleSubmitProps) {
    setIsLoading(true);

    const formData = {
      ...data,
      status: 1,
    };

    try {
      if (formType === 'post') {
        await api.post(getSubDomain() + 'users', formData);

        toast.success('Salvo com sucesso');
      } else {
        if (contactId) await api.put(getSubDomain() + `user/${contactId}`, formData);
        toast.success('Salvo com sucesso');
      }
      setModalIsOpen(false);

      formRef.current.resetFields();
    } catch (err) {
      console.log(err);
      toast.error('Erro ao Salvar');
    }

    setIsLoading(false);
    refetch();
  }

  return (
    <FormWrapper>
      <AntdForm
        name="basic"
        onFinish={handleSubmit}
        style={{ padding: '30px' }}
        layout="vertical"
        initialValues={initialFields}
        ref={formRef}
      >
        <CardWrapper type="inner" title="Informações Gerais">
          <AntdForm.Item
            label="Nível de Acesso"
            name="role"
            rules={[{ required: true, message: 'Campo Obrigatório!' }]}
          >
            <Select placeholder="Selecione um Nível" value={status} onChange={e => setStatus(e)}>
              <Select.Option value={'USER'}>Usuário</Select.Option>
              <Select.Option value={'ESCOLA'}>Escola</Select.Option>
              <Select.Option value={'ADMIN'}>Admin (Gestor)</Select.Option>
              <Select.Option value={'SUPERADMIN'}>SuperAdmin (Norte)</Select.Option>
            </Select>
          </AntdForm.Item>

          <AntdForm.Item
            label="Nome"
            name="nome"
            rules={[{ required: true, message: 'Campo Obrigatório!' }]}
            wrapperCol={{ span: 16 }}
          >
            <Input placeholder="Nome Completo" />
          </AntdForm.Item>

          <AntdForm.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Campo Obrigatório!' }]}
            wrapperCol={{ span: 16 }}
          >
            <Input placeholder="Email" />
          </AntdForm.Item>

          <AntdForm.Item
            label="Senha"
            name="password"
            rules={[{ required: true, message: 'Campo Obrigatório!' }]}
            wrapperCol={{ span: 16 }}
          >
            <Input placeholder="Senha" />
          </AntdForm.Item>

          {status === 'ESCOLA' && (
            <SelectEscola
              name="idEscola"
              disabled={formType === 'view' ? true : false}
              title="Escola"
              fetchUrl="escolas"
            />
          )}
        </CardWrapper>

        <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
          <Button type="default" size="large" htmlType="button" onClick={clearFieldsAndCloseModal}>
            Cancelar
          </Button>

          <Button type="primary" size="large" htmlType="submit" loading={isLoading}>
            <FeatherIcon icon="check" size={14} style={{ marginRight: 10 }} />
            Salvar
          </Button>
        </footer>
      </AntdForm>
    </FormWrapper>
  );
}
