import React from 'react';

import { TableComponent } from './components/table';

import { ThemeLayout } from '../../layout/themeLayout';
import { Main, TableWrapper } from '../../container/styled';

import { PageHeader } from '../../components/page-headers/page-headers';
import { Cards } from '../../components/cards/frame/cards-frame';

import { useFetch } from '../../hooks/useFetch';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'antd';
import { FilterButton } from './components/FilterButton';
import Alert from '../../components/alerts/alerts';

export function DiarioEstudantes() {
  const params = useParams() as any;
  const {
    isFetching,
    dataFetch,
    refetch,
    currentPage,
    itensPerPage,
    setCurrentPage,
    setColumnOrdenation,
    setItensPerPage,
    filters,
    handleAddFilters,
    clearFilters,
    totalItens,
    cityName,
    handleChangeCityFilter,
  } = useFetch<any>({ baseUrl: `diarioestudantes?idDiario=${params.id}`, isLinkProps: false });

  const history = useHistory();
  return (
    <ThemeLayout>
      {cityName && (
        <Alert
          type="warning"
          message={`O filtro aplicado resulta em uma lista de registros relacionados às ROTAS da cidade de ${cityName}`}
        />
      )}

      <PageHeader
        ghost
        title={`Presenças - Rota ${params.sigla} - ${params.data.replaceAll('Z', '/')}`}
        buttons={[
          <FilterButton
            handleChangeCityFilter={handleChangeCityFilter}
            filters={filters}
            handleAddFilters={handleAddFilters}
            clearFilters={clearFilters}
          />,
          <Button onClick={() => history.push('/visualizacaodiario')}>Voltar</Button>,
        ]}
      />

      <Main>
        <Cards headless>
          <TableWrapper className="table-order table-responsive">
            <TableComponent
              setCurrentPage={setCurrentPage}
              setColumnOrdenation={setColumnOrdenation}
              currentPage={currentPage}
              refetch={refetch}
              isLoading={isFetching}
              dataFetch={dataFetch}
              totalItens={totalItens}
              itensPerPage={itensPerPage}
              setItensPerPage={setItensPerPage}
            />
          </TableWrapper>
        </Cards>
      </Main>
    </ThemeLayout>
  );
}
