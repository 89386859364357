import React from 'react';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';

import { IItemProps } from '../../types';
import { TableButtonDelete } from './TableButtonDelete';
import { TableButtonEdit } from './TableButtonEdit';
import { TableButtonRestore } from './TableButtonRestore';

interface ITableColumnControllerProps {
  handleDeleteFunction: (contactId: string) => Promise<void>;
  handleRestoreFunction: (contactId: string) => Promise<void>;
  refetch: () => void;
  dataList?: IItemProps[];
}

export function TableColumnController({
  handleDeleteFunction,
  handleRestoreFunction,
  refetch,
  dataList,
}: ITableColumnControllerProps) {
  const columns: ColumnsType<IItemProps> = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      sorter: true,
    },

    {
      title: 'Data',
      dataIndex: 'data',
      sorter: true,
      render: (_, data) => <p>{new Date(data.createdAt || '').toLocaleDateString()}</p>,
    },

    {
      title: 'Nome Pessoa',
      dataIndex: 'personName',
      sorter: true,
      render: (_, data) => <p>{data.pessoa.nome}</p>,
    },

    {
      title: 'Status',
      dataIndex: 'status',
      render: (_, row: IItemProps) => (
        <span
          style={{
            color: row.status === 2 ? '#20C9A5' : '#faad14',
            background: row.status === 2 ? '#E2F4F0' : '#fffbe6',
            border: `0px solid ${row.status === 2 ? '#b7eb8f' : '#ffe58f'}`,
            padding: '3px 10px',
            borderRadius: 40,
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            boxSizing: 'border-box',
            fontVariant: 'tabular-nums',
            listStyle: 'none',
            fontFeatureSettings: 'tnum',
            display: 'inline-block',
            height: 'auto',
            fontSize: '12px',
            lineHeight: '20px',
            whiteSpace: 'nowrap',
            transition: 'all 0.3s',
          }}
        >
          {row.status === 2 ? 'Respondido' : 'Não Respondido'}
        </span>
      ),
    },

    {
      title: 'Ações',
      dataIndex: 'action',
      render: (_, row: IItemProps) => <TableButtonEdit contactId="" refetch={refetch} data={row} />,
    },
  ];

  return { columns, data: dataList };
}
